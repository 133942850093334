/* Supplier page */
.supplierPageDropdown {
    height: 50px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
}

.supplierPageDropdown .ratingLink {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

.testIcon {
    height: 24px !important;
    width: 24px !important;
}

.supplierRatingContainer {   
    display: flex;
    justify-content: space-between;
    flex-direction: column; 
    width: 100%;
    height: 250px;
    border-radius: var(--borderRadiusReg);
    box-shadow: 1px 1px 6px 1px var(--gray); 
    margin-bottom: 40px;
}

.ratingRowTop {
    height: 50px;
    padding: 0px 10px;
    border-bottom: 2px solid var(--mediumGray);
    display: flex;
    justify-content: center;
    align-items: center;
}
.ratingRowCenter {
    display: flex;
    justify-content: center;
    padding: 10px;
}
.ratingRowBottom {
    height: 35px;
    padding: 10px;
    border-top: 2px solid var(--mediumGray);
    display: flex;
    justify-content: center;
    align-items: center;
}

.ratingRowBottom .ratingLink {
    display: flex;
    justify-content: center;
    flex-direction: row;
}

.supplierPageDropdownCenter {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 130px;
}

/* Products page */
.productsContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    margin: 50px 0px;
}

.productItem {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid white; 
    border-radius: var(--borderRadiusReg);
    padding: 5px;
    margin: 20px;
    min-height: 200px;
    width: 130px;  
}

.productItem:hover {
    box-shadow: 1px 0px 6px 1px var(--black); 
    border: 1px solid var(--gray); 
}

.productItem .buyBtn {
    cursor: pointer;
    width: 50px;
    border: 1px solid var(--gray);
    border-radius: 25px;
}

.productItem p{
    font-size: 0.625em;
}

.productItem img {
    border-radius: var(--borderRadiusReg) var(--borderRadiusReg) 0px 0px;
    width: 100%;
    height: 100px;
}

.productRating {
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 5px 0px;
}

.editBtn {
    font-size: 0.75em;
}

.deleteBtn {
    font-size: 0.75em;
    margin-right: 5px;
}


/* Product page */

.sellerLogo {    
    align-self: flex-start;
    width: 15%;
    margin: 10px 0px 60px 0px;
}

.sellerLogo img {   
    max-height: 120px;
    border-radius: 5px;
}

.productPageContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 1540px;
    margin: 0px auto;
}

.productPageTop {    
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    max-width: 1240px;
    height: 80%;
}

.productPageTop .headerCont {    
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: 0px 0px 20px 0px;
}

.productPageTop .supplierTrustedIcon {    
    height: 30px;
    min-width: 30px;
}

.productPageTop ul {
    padding: 10px 0px 0px 0px;
    margin: 0px;
    justify-content: space-between;
    border-top: 0.8px solid var(--gray);
}

.productPageTop .leftColumn {
    width: 50%;
    padding: 0px 40px 0px 0px;
}

.productPageTop .leftColumn h5 {
    cursor: pointer;
    width: 100%;
    padding-top: 10px;
    margin-top: 20px; 
}

.productPageTop .leftColumn li {
    font-weight: bold;
    margin: 10px 0px;
    list-style-type: none;
}

.productPageTop .centerColumn {
    min-width: 20%;
    padding: 0px 40px;
}

.productPageTop .centerColumn h4 {
    display: flex;
    align-items: center;
}

.productPageTop .centerColumn img {
    max-height: 100%;
    max-width: 100%;
    object-fit: cover;
    border-radius: var(--borderRadiusReg);
}

.productPageTop .rightColumn {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    max-height: 200px; 
    width: 30%;
    padding: 0px 0px 0px 40px;
}

.productPageTop .rightColumn .btnCont {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.productPageTop .centerColumn h3 {
    padding: 0px 0px 5px 0px;
    display: inline;
}

.productPageTop .centerColumn h1 {
    margin: 20px 0px;
}

.productPageTop .centerColumn p {
    padding: 10px 0px;    
}

.productPageTop .rightColumn .submitBtn {
    margin: 0px 5px;
    width: 230px;
}

.rightColumn form {
    flex-grow: 10;
    margin: 0px 10px 0px 5px;
}

.rightColumn .icon {
    margin: 0px 5px;
}

.productPageTop .rightColumn .productQuantity {
    display: flex;
    flex-wrap: nowrap;
}

.productPageTop .rightColumnRow1, 
.productPageTop .rightColumnRow2, 
.productPageTop .rightColumnRow4 {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 5px 0px;
}

.productPageTop .rightColumnRow3 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px 0px;
}

.productVariationImages {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 10px 0px 0px 0px;
}

.productVariationImages img {
    height: 100%;
    width: 100%;
}

.productPageBottom {
    display: flex;
    flex-direction: row;    
    justify-content: space-between;
}

.incrementQuantity {
    height: 27px;
    border: 1px solid var(--turqDark);
    border-left: 0px;
    border-radius: 0px 12.5px 12.5px 0px;
}

.decrementQuantity {
    height: 27px;    
    border: 1px solid var(--turqDark);
    border-right: 0px;
    border-radius: 12.5px 0px 0px 12.5px;
}

@media only screen and (max-width: 1024px) {
    .productPageTop {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex-wrap: wrap;
        margin: 50px 0px;
    }

    .productPageTop .centerColumn {
        width: 90%;
    }

    .hideTextOverflow {
        height: 350px;
    }

    .productPageTop .leftColumn {
        width: 90%;
    }
}