.dashboardRow {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.dashboardRow a {
	margin: 20px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	text-decoration: none;
}

.dashboardRow a,
.overviewItemFooter a {
	height: 50px;
	width: 230px;
}

.trustedSupplierContainer {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	padding: 0px 10%;
	width: 100%;
	height: 100px;
	background-color: var(--creamWhite);
}

.supplierContainer {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	border-bottom: 2px solid var(--gray);
	margin: 20px 0px;
	padding: 5px 0px;
}

.supplierContainer > div {
	margin: 0px 20px;
}

.supplierContainer .supplierText {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	margin-left: 20px;
}

.supplierContainer img {
	height: 100px;
	width: 100px;
}

/* Overview */
.mainOverview {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	background-color: var(--lightGray);
	width: 100%;
	padding: 0px 0px 20px 0px;
}

.overviewSection {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	padding: 24px;
	width: 100%;
	max-width: 1540px;
	min-width: 250px;
	border-radius: 25px;
	background-color: var(--overviewBg);
	gap: 40px;
}

.overviewItem {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-flex: 1;
	-ms-flex: 1;
	flex: 1;
	background-color: var(--white);
	border-radius: var(--borderRadiusDash);
	width: 100%;
	min-width: 250px;
	-webkit-box-shadow: var(--boxShadowWidget);
	box-shadow: var(--boxShadowWidget);
}

.overviewItem .overviewItemBody {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	max-width: 100%;
	height: 280px;
	padding: 10px;
}

.overviewItem a {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	text-decoration: none;
	color: var(--gray-700);
	height: 24px;
}

.overviewItem a:hover {
	color: var(--turqMain);
}

.overviewItem .nothingMsg {
	position: absolute;
	margin: 75px auto;
}

.overviewItemHeader {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	position: relative;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	border-radius: var(--borderRadiusDash) var(--borderRadiusDash) 0px 0px;
	width: 100%;
	padding: 4px 0px;
	height: 40px;
}

.overviewItemHeader h3 {
	padding: 0px 20px;
	color: var(--white);
}

.overviewItemHeader .headerIcon {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	margin-right: 20px;
	height: 32px;
	width: 32px;
	fill: var(--white);
}

.overviewItem .overviewItemBody .cardLink {
	border-radius: 6px;
	padding: 6px 12px;
}

.overviewItem .overviewItemBody .cardLink:nth-child(even) {
	background-color: var(--gray-50);
}

.overviewItemFooter {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: end;
	-ms-flex-align: end;
	align-items: flex-end;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	margin: 10px 0px;
	height: 40px;
	width: 100%;
	gap: 12px;
}
.overviewItemFooter button {
	-webkit-box-flex: 1;
	-ms-flex: 1;
	flex: 1;
}

/* Dashboard Overview Table Rows */
.overviewItem table {
	width: 98%;
	height: 100px;
	margin: 10px 0px;
}

.overviewItem tbody {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
}

.overviewItem tr {
	display: block;
	min-width: 100%;
}

.overviewItem tr td {
	display: block;
	height: 30px;
	min-width: 100%;
}

.overviewItem tr td h4 {
	padding: 0px 5px;
}

/* Quicknav */
.listContainer {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	margin: 50px 0px;
}

.listItems {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
}

/* Buttons */
.dashboardBtn {
	cursor: pointer;
	width: 240px;
	height: 40px;
	border-radius: 25px;
	color: var(--black);
	font-size: 1em;
	-webkit-box-shadow: 1px 1px 6px var(--boxShadowDarkest);
	box-shadow: 1px 1px 6px var(--boxShadowDarkest);
}

.dashboardBtn svg {
	padding-right: 20px;
}

.dashboardBtnSeeAll {
	-webkit-box-shadow: 1px 1px 6px var(--boxShadowDarkest);
	box-shadow: 1px 1px 6px var(--boxShadowDarkest);
	font-variation-settings: var(--bold);
	color: var(--gray-500);
	border-radius: 25px;
	font-size: 0.875em;
	cursor: pointer;
	width: 240px;
	height: 40px;
}

.dashboardBtn:hover,
.dashboardBtnSeeAll:hover {
	background-color: var(--veryLightGray);
	-webkit-box-shadow: 1px 1px 6px var(--boxShadowDarkBackgroundHover);
	box-shadow: 1px 1px 6px var(--boxShadowDarkBackgroundHover);
}

@media only screen and (max-width: 1528px) {
	.overviewItem:nth-child(4) {
		display: none;
	}
}

@media only screen and (max-width: 680px) {
	.overviewSection {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		border-radius: 0px;
		padding: 12px;
	}
	.carouselInnerContainer {
		border-radius: 0px;
	}

	.overviewItem {
		margin: 12px 0px;
	}
}
