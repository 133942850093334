.gridContainer {
	display: -ms-grid;
	display: grid;
	-ms-grid-columns: 1fr 40px 1fr 40px 1fr 40px 1fr;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	width: 100%;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	max-width: 1540px;
	margin: 0px auto;
	border-radius: 24px;
	padding: 60px;
	grid-gap: 40px;
	background-color: var(--teamManageBg);
}

.gridContainer a {
	max-width: 320px;
	min-width: 250px;
	width: 100%;
}

.gridItem {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-filter: drop-shadow(0px 0px 3px var(--boxShadowDarkest));
	filter: drop-shadow(0px 0px 3px var(--boxShadowDarkest));
	border-radius: var(--borderRadiusReg);
	background-color: var(--veryLightGray);
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	max-width: 320px;
	min-width: 250px;
	cursor: pointer;
	height: 130px;
	width: 100%;
}

.gridItemSkel {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-filter: drop-shadow(0px 0px 3px var(--boxShadowDarkest));
	filter: drop-shadow(0px 0px 3px var(--boxShadowDarkest));
	background-color: var(--veryLightGray);
	border-radius: var(--borderRadiusReg);
	-ms-grid-columns: 80% 20%;
	grid-template-columns: 80% 20%;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-ms-grid-rows: 40% 60%;
	grid-template-rows: 40% 60%;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	max-width: 320px;
	min-width: 250px;
	height: 130px;
	opacity: 0.3;
	width: 100%;
}

.gridItemSkel > *:nth-child(1) {
	-ms-grid-row: 1;
	-ms-grid-column: 1;
}

.gridItemSkel > *:nth-child(2) {
	-ms-grid-row: 1;
	-ms-grid-column: 2;
}

.gridItemSkel > *:nth-child(3) {
	-ms-grid-row: 2;
	-ms-grid-column: 1;
}

.gridItemSkel > *:nth-child(4) {
	-ms-grid-row: 2;
	-ms-grid-column: 2;
}

.gridItem .mainCont {
	overflow: hidden;
	padding: 16px;
}

.gridItem:hover {
	-webkit-filter: drop-shadow(0px 0px 3px var(--boxShadowDarkBackgroundHover));
	filter: drop-shadow(0px 0px 3px var(--boxShadowDarkBackgroundHover));
}

.gridHeader h2 {
	font-variation-settings: var(--bold);
}

.gridHeader h2,
.gridBody p {
	-ms-grid-column-align: start;
	justify-self: start;
	-ms-flex-item-align: start;
	-ms-grid-row-align: start;
	align-self: start;
}

.gridItem .gridIcon {
	-ms-grid-column-align: end;
	justify-self: end;
	height: 36px;
	width: 36px;
}

.gridItem .gridIcon path {
	fill: var(--white) !important;
}

.gridItem .iconColumn,
.gridItemSkel .iconColumn {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	height: 100%;
	padding: 12px;
	min-width: 60px;
	max-width: 60px;
	border-radius: 0px 10px 10px 0px;
}

.gridItemSkel .iconColumn.skel {
	background-color: var(--mediumGray);
}
.gridItem .iconColumn.team {
	background-color: var(--team);
}
.gridItem .iconColumn.selling {
	background-color: var(--sale);
}
.gridItem .iconColumn.analytics {
	background-color: var(--analytics);
}
.gridItem .iconColumn.purchase {
	background-color: var(--purchase);
}
.gridItem .iconColumn.legal {
	background-color: var(--legal);
}
.gridItem .iconColumn.apps {
	background-color: var(--apps);
}

.gridBody p {
	font-size: 0.875em;
}

@media only screen and (max-width: 1280px) {
	.gridContainer {
		-ms-grid-columns: 1fr 40px 1fr 40px 1fr;
		grid-template-columns: 1fr 1fr 1fr;
	}
	.gridContainer .gridItem {
		height: 140px;
	}
}
@media only screen and (max-width: 900px) {
	.gridContainer {
		-ms-grid-columns: 1fr 40px 1fr;
		grid-template-columns: 1fr 1fr;
	}
}
@media only screen and (max-width: 768px) {
	.gridContainer {
		display: -ms-grid;
		display: grid;
		-ms-grid-row-align: center;
		align-self: center;
		padding: 20px 10px;
	}

	.gridContainer a {
		-ms-grid-column-align: center;
		justify-self: center;
	}
}
@media only screen and (max-width: 640px) {
	.gridContainer {
		-ms-grid-columns: 1fr;
		grid-template-columns: 1fr;
	}
}
