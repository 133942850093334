.carousel {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	width: 100%;
}

.carouselHeader {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-align: end;
	-ms-flex-align: end;
	align-items: flex-end;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	width: 100%;
	max-width: 1540px;
	padding: 60px 25px 36px 25px;
}
.carouselHeader.short {
	padding: 0px 0px 26px 26px;
}
.carouselHeader.short .supplierTrustedIcon {
	fill: var(--buy-700-main);
	margin-bottom: 3px;
}
.carouselHeader.short h2 {
	color: var(--buy-700-main);
	line-height: 32px;
}
.carouselHeader.short .carouselHeaderBtnCont a {
	margin: 0px;
}
.carouselHeader.short .carouselHeaderBtnCont button {
	margin: 6px 0px;
}
.carouselHeader.short .carouselHeaderBtnCont button .btnIcon {
	fill: var(--gray-500);
	margin-right: 16px;
	height: 20px;
	width: 20px;
}

.carousel .leftDesc {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	padding: 24px;
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	height: 100%;
	width: 225px;
}
.carousel .leftDesc h4 {
	color: var(--buy-700-main);
}
.carousel .leftDesc p {
	margin-top: 24px;
	font-size: 0.75em;
}

.carousel .carouselHeader .carouselHeaderBtnCont {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: reverse;
	-ms-flex-direction: row-reverse;
	flex-direction: row-reverse;
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	width: 50%;
}

.carousel .carouselHeader .supplierTrustedIcon {
	fill: var(--successGreen);
}

.carousel .carouselHeader .carouselHeaderBtnCont button,
.carousel .carouselHeader .carouselHeaderBtnCont a {
	margin: 10px;
}

.carouselInnerContainer {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	width: 100%;
	min-width: 360px;
	max-width: 1540px;
	border-radius: 25px;
	padding: 36px 0px;
	margin-bottom: 50px;
}

.carouselItems {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-item-align: center;
	-ms-grid-row-align: center;
	align-self: center;
	overflow: hidden;
	min-height: 250px;
	min-width: 360px;
	max-width: 100%;
	width: 1440px;
	padding: 24px 0px;
}

/* Learning Zone Carousel */

.learningZoneCont {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	max-height: 420px;
	padding: 0px 20px;
	gap: 40px;
}

.learningCard {
	-webkit-box-shadow: var(--shadow-xl);
	box-shadow: var(--shadow-xl);
	background-color: var(--white);
	border-radius: var(--borderRadiusReg);
	min-width: 320px;
	overflow: hidden;
	cursor: pointer;
	height: 420px;
}
.learningCard:hover {
	-webkit-filter: drop-shadow(0px 0px 6px var(--boxShadowDarkest));
	filter: drop-shadow(0px 0px 6px var(--boxShadowDarkest));
}

.learningCardSkel {
	-webkit-box-shadow: 0px 0px 6px var(--boxShadowDark);
	box-shadow: 0px 0px 6px var(--boxShadowDark);
	background-color: var(--mediumGray);
	border-radius: var(--borderRadiusReg);
	margin: 0px 20px;
	overflow: hidden;
	min-width: 320px;
	cursor: default;
	height: 420px;
	opacity: 0.3;
}
.learningCardSkel:hover {
	-webkit-filter: none;
	filter: none;
}

.cardImg {
	border-radius: var(--borderRadiusReg) var(--borderRadiusReg) 0px 0px;
	height: 240px;
	width: 100%;
}

.cardImg .learningIcon path {
	border-radius: var(--borderRadiusReg) var(--borderRadiusReg) 0px 0px;
	width: 100%;
}

.cardFooter {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	height: 180px;
	padding: 20px;
	border-radius: 0px 0px var(--borderRadiusReg) var(--borderRadiusReg);
	color: var(--black);
	background-color: var(--white);
}

.cardFooter h4 {
	font-variation-settings: var(--bold);
}
.cardFooter .linkText {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	color: var(--primary-700);
	font-size: 1em;
	gap: 8px;
}
.cardFooter .linkText:hover {
	color: var(--primary-900);
	font-size: 1em;
}
.cardFooter .linkText:hover svg {
	fill: var(--primary-900);
}

.cardFooter .linkText svg {
	fill: var(--primary-700);
	height: 20px;
	width: 20px;
}

.arrow {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	background-color: var(--white);
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	fill: var(--gray-700);
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	border-radius: 50%;
	cursor: pointer;
	height: 24px;
	width: 24px;
	padding: 10px;
}

.arrowRight {
	margin: auto 8px auto 0px;
}
.arrowLeft {
	margin: auto 0px auto 8px;
}

/* Supplier item */
.suppliersCont {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.suppliersCont .supplier {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	background-color: var(--white);
	cursor: pointer;
	width: 320px;
	max-height: 280px;
	height: 280px;
	margin: 0px 20px;
	-webkit-box-shadow: var(--boxShadowCrslCard);
	box-shadow: var(--boxShadowCrslCard);
	border-radius: 12px;
}
.suppliersCont .supplierSkel {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	width: 320px;
	margin: 0px 20px;
	-webkit-filter: drop-shadow(2px 2px 6px var(--boxShadowCrslCard));
	filter: drop-shadow(2px 2px 6px var(--boxShadowCrslCard));
	opacity: 0.3;
	border-radius: 12px;
}

.suppliersCont .supplier:hover {
	-webkit-filter: drop-shadow(2px 2px 6px var(--boxShadowDark));
	filter: drop-shadow(2px 2px 6px var(--boxShadowDark));
}

.suppliersCont a {
	text-decoration: none;
	color: var(--black);
}

.suppliersCont .supplier .supplierHeader,
.suppliersCont .supplierSkel .supplierHeader {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	background-color: var(--buy-700-main);
	border-radius: 12px 12px 0px 0px;
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	position: relative;
	padding: 12px 20px;
	height: 48px;
	width: 100%;
}

.suppliersCont .supplier .supplierBtn {
	-webkit-box-shadow: 1px 1px 6px var(--boxShadowDarkest);
	box-shadow: 1px 1px 6px var(--boxShadowDarkest);
	border-radius: var(--borderRadiusBtn);
	padding: 0px 10px 0px 10px;
	color: var(--primary-600);
	font-size: 0.875em;
	cursor: pointer;
	width: 164px;
	height: 36px;
}
.suppliersCont .supplier .btn3-secondary {
	min-width: 130px;
	height: 36px;
}
.suppliersCont .supplier .btn3-primary {
	background-color: var(--primary-600);
	min-width: 130px;
	height: 36px;
}
.suppliersCont .supplier .supplierBtn:hover {
	-webkit-box-shadow: 1px 1px 6px var(--boxShadowDarkBackgroundHover);
	box-shadow: 1px 1px 6px var(--boxShadowDarkBackgroundHover);
}
.suppliersCont .supplier .supplierBtn.gray {
	color: var(--gray-500);
}
.suppliersCont .supplier .trashIcon {
	fill: var(--gray-500);
	height: 20px;
	width: 20px;
}
.suppliersCont .supplier .btnIcon {
	fill: var(--primary-600);
	margin-right: 16px;
	height: 24px;
	width: 24px;
}
.suppliersCont .supplier .btnIcon.gray {
	fill: var(--gray-500);
	margin-right: 16px;
	height: 20px;
	width: 20px;
}
.suppliersCont .supplier .btnIcon.white {
	fill: var(--white);
	margin-right: 16px;
	height: 20px;
	width: 20px;
}

.suppliersCont .supplier .headerIcon {
	fill: var(--success-400);
	height: 24px;
	width: 24px;
}

.suppliersCont .supplier .supplierHeader h3 {
	margin: 55px 30px 0px 30px;
	font-size: 1.5em;
	text-align: center;
}

.suppliersCont .supplier .supplierBody,
.suppliersCont .supplierSkel .supplierBody {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: flex-start;
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	overflow: hidden;
	height: 150px;
	width: 100%;
	padding: 24px 20px 0px 20px;
	background-color: var(--white);
}

.suppliersCont .supplier .supplierBody h3 {
	height: 60px;
}
.suppliersCont .supplier .supplierBody p {
	max-height: 62px;
}

.suppliersCont .supplier .supplierFooter,
.suppliersCont .supplierSkel .supplierFooter {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	border-radius: 0px 0px 12px 12px;
	background-color: var(--white);
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	padding: 0px 20px;
	height: 80px;
	width: 100%;
}

/* Hero carousel */
.heroCarousel {
	display: inline-block;
	border-radius: 24px 24px 0px 0px;
	overflow: hidden;
	width: 100%;
}
.heroCarousel .header {
	letter-spacing: -1.2px;
	line-height: 72px;
	font-size: 3.75em;
}
.heroCarousel .paragraph {
	margin: 24px 0px 48px 0px;
	color: var(--gray-600);
	line-height: 30px;
	font-size: 1.25em;
}
.heroCarousel .innerCont {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	width: 100%;
}
.heroCarousel .innerCont .card {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	border-radius: 24px;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	width: 100%;
}
.heroCarousel .innerCont .card .col {
	padding: 132px 64px 132px 162px;
	max-width: 544px;
}
.heroCarousel .carouselNav {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	position: absolute;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	width: 100%;
	bottom: 6px;
	gap: 6px;
}
.heroCarousel .carouselNav .dot {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	fill: var(--gray-300);
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	cursor: pointer;
	height: 12px;
	width: 12px;
}
.heroCarousel .carouselNav .dot.selected {
	fill: var(--gray-500);
}

@media only screen and (max-width: 768px) {
	.carouselInnerContainer {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		padding: 20px 0px;
	}

	.carousel .carouselHeader {
		text-align: center;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
	}
	.carousel .carouselHeader .flexRowStart {
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.arrowLeft,
	.arrowRight {
		margin: auto 0px;
	}

	.carousel .carouselHeader .carouselHeaderBtnCont {
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
		width: 100%;
	}
	.heroCarousel .innerCont .card .col {
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		text-align: center;
		padding: 48px;
	}
	.heroCarousel .innerCont .card button {
		margin: auto;
	}
	.heroCarousel .innerCont .card img {
		display: none;
	}
}
@media only screen and (max-width: 648px) {
	.carouselHeader,
	.carouselHeader.short {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		padding: 24px;
		gap: 12px;
	}
	.heroCarousel .innerCont .card .col {
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		text-align: center;
		padding: 48px 12px;
	}
}
