/* Chat related css */

.messagingContainer {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: end;
	-ms-flex-align: end;
	align-items: flex-end;
	width: 100%;
	height: 650px;
	max-height: 80vh;
}

.chatContainer {
	width: 100%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.chatBox {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	margin-bottom: 10px;
	width: 100%;
	height: 100%;
	min-height: 400px;
	max-height: 400px;
	border-top: 1px solid var(--gray);
	overflow: auto;
}

.chatBox h4 {
	text-align: center;
	margin: 20px 0px;
}

.submitCont {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	width: 100%;
	border-top: 1px solid var(--gray);
	padding: 20px 0px 5px 0px;
}

.chatContactHeader {
	cursor: pointer;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	width: 100%;
	height: 50px;
	margin: 20px 0px;
}

.chatContactHeader a {
	color: var(--gray);
}

.chatContactHeader a:hover {
	color: var(--black);
}

.contactBox {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	min-width: 375px;
	height: 100%;
	font-size: 0.75em;
	background-color: var(--lightBlue);
	border-right: 0.6px solid var(--turqDark);
	overflow: auto;
}

.contactName {
	margin: 0;
}

.contactName:hover {
	color: var(--turqoise);
}

.contactDiv {
	cursor: pointer;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	padding: 5px 5%;
	width: 90%;
}

.contactDivActive {
	background-color: var(--turqDark);
}

.contactDiv:hover {
	background-color: var(--turqLight);
	color: var(--turqMain);
}

.contactDiv p {
	height: 100%;
	margin: 0px 5px;
	color: #a1a1a1;
}

.contactDiv h4 {
	margin: 0px 5px;
}

.contactDiv img {
	width: 40px;
	height: 40px;
	margin: 0px 5px;
	border-radius: 20px;
	border: 1px solid var(--lightGray);
	background-color: var(--white);
}

.lastMessageDate {
	margin: 100px;
}

.centerContactDiv {
	height: 100%;
}

/* Chat message related css */

.messageInput {
	border-radius: 25px;
	height: 40px;
	background-color: var(--lightGray);
	margin-right: 10px;
	width: 100%;
	padding: 0px 10px;
	font-size: 0.875em;
}

.chatSubmitBtn {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	border-radius: 50%;
	height: 40px;
	min-width: 40px;
	margin: 0px;
	padding: 0px;
	font-size: 0.875em;
	cursor: pointer;
	background-color: var(--turqDark);
	color: var(--white);
}

.chatSubmitBtn:hover {
	-webkit-box-shadow: 1px 0px 6px 1px var(--black);
	box-shadow: 1px 0px 6px 1px var(--black);
}

.chatSubmitBtnDisabled {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	border-radius: 50%;
	height: 40px;
	min-width: 40px;
	margin: 0px;
	padding: 0px;
	font-size: 0.875em;
	opacity: 0.5;
	fill: var(--lightGray);
}

.messageUserName {
	color: var(--turqDark);
	font-size: 0.875em;
	margin-right: 15px;
}

.messageContent {
	margin: 0px;
	font-size: 0.875em;
}

.chatMessageUser {
	background-color: var(--lightGray);
	border-radius: 10px 10px 0px 10px;
	padding: 5px 10px;
	margin: 5px;
	width: 350px;
	max-width: 60%;
	word-wrap: break-word;
	margin-left: auto;
}

.chatMessage {
	background-color: var(--turqLight);
	border-radius: 10px 10px 10px 0px;
	padding: 5px 10px;
	margin: 5px;
	width: 350px;
	max-width: 60%;
	word-wrap: break-word;
	margin-right: auto;
}

.chatMessage.fullWidth,
.chatMessageUser.fullWidth {
	max-width: 80%;
	width: 100%;
}

.messageTopCont {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
}

.chatTimestamp {
	max-width: 60%;
	word-wrap: break-word;
	font-size: 0.875em;
	font-weight: 300;
}

@media only screen and (max-width: 1024px) {
	.chatContainer {
		width: 100%;
	}
}
