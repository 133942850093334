/* CSS connected to team components */

/* Statuses */
.statusGreen {
	color: var(--successGreen);
}

/* General */
.teamHeader {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-align: end;
	-ms-flex-align: end;
	align-items: flex-end;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	width: 100%;
	max-width: 1540px;
	padding: 0px 24px 32px 24px;
	flex-wrap: wrap;
	color: var(--team-700-main);
	gap: 12px;
}
.teamHeader .btnCont {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	gap: 20px;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.teamHeader .mainIcon {
	fill: var(--team-700-main);
	height: 24px;
	width: 24px;
}

/* ## Invoices  */
.infoSection {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	gap: 24px;
}
.infoSection .invoiceInput,
.buyerInfoSection .invoiceInput {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	max-width: 320px;
	min-width: 140px;
	max-height: 64px;
	height: 100%;
	width: 100%;
}
.buyerInfoSection .invoiceInput input {
	border-radius: 8px;
}
.infoSection .invoiceInput input {
	margin: 4px 0px 0px 0px;
	border-radius: 8px;
}
.infoSection .invoiceInput p,
.buyerInfoSection .invoiceInput p {
	color: var(--gray-500);
	font-size: 0.875em;
	font-weight: 600;
	margin: 0px;
}
.infoSection .invoiceInput h4,
.buyerInfoSection .invoiceInput h4 {
	font-variation-settings: 'wght' 700;
	font-size: 1em;
}

.buyerInfoSection {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	margin-bottom: 32px;
}
.buyerInfoSection h4 {
	color: var(--sell-new-700);
}
.buyerInfoSection .col {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: flex-start;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	gap: 24px;
}
.buyerInfoSection .invoiceInput {
	min-width: 100px;
}

.invoiceItemsSection {
	position: relative;
	border-radius: 12px;
	border: 1px solid var(--gray-200);
	-webkit-box-shadow: var(--focus-ring-shadow-sm);
	box-shadow: var(--focus-ring-shadow-sm);
	overflow-x: hidden;
	overflow-y: auto;
	width: 100%;
}
.invoiceItemsSection svg:focus {
	border-radius: 50%;
}
.invoiceItemsSection table {
	background: var(--white);
	border-collapse: collapse;
	width: 100%;
	text-align: left;
}

.invoiceItemsSection th.thin {
	width: 4%;
}
.invoiceItemsSection th.wide {
	width: 45%;
}
.invoiceItemsSection tr td:nth-child(9) {
	text-align: right;
}
.invoiceItemsSection td {
	text-wrap: nowrap;
	padding: 8px;
}
.invoiceItemsSection td p {
	height: 100%;
}
.invoiceItemsSection tr.editing {
	background-color: var(--orders-100);
}
.invoiceItemsSection tr .editable input {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	border-radius: 8px;
	padding: 0px 12px;
	min-width: 80px;
	width: 100%;
}
.invoiceItemsSection tr .editable input:focus {
	-webkit-box-shadow: var(--focus-ring-4px-primary-100);
	box-shadow: var(--focus-ring-4px-primary-100);
}
.invoiceItemsSection tr .editable input:hover {
	background-color: var(--gray-50);
}
.invoiceItemsSection tr .editable .selectCustom {
	border-radius: 8px;
	min-width: 80px;
	border: none;
}
.invoiceItemsSection tr .editable .selectCustom svg {
	display: none;
}
.invoiceItemsSection tr .editable .selectCustom:focus {
	-webkit-box-shadow: var(--focus-ring-4px-primary-100);
	box-shadow: var(--focus-ring-4px-primary-100);
}
.invoiceItemsSection tr.editing .editable .selectCustom svg {
	display: block;
}
.invoiceItemsSection tr .editable .selectCustom:hover,
.invoiceItemsSection tr .editable .selectCustom:hover svg {
	background-color: var(--gray-50);
}
.invoiceItemsSection tr .editable .selectCustom .options {
	min-width: 100px;
}

.invoiceItemsSection .topBar {
	border-radius: 12px 12px 0px 0px;
	overflow: hidden;
	background-color: var(--gray-50);
}
.invoiceItemsSection .topBar .header {
	color: var(--gray-600);
	font-size: 0.875em;
	text-wrap: nowrap;
	padding: 8px;
}
.invoiceItemsSection .btnAddCont {
	padding: 20px 40px 20px 58px;
	margin: 0px;
}
.invoiceItemsSection .trashIcon {
	height: 20px;
	width: 20px;
	fill: var(--warningRed);
	padding: 10px;
}
.invoiceItemsSection .trashIcon:hover {
	background-color: var(--gray-100);
	border-radius: 50%;
	cursor: pointer;
}

.amountSection {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	padding: 0px 24px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: end;
	-ms-flex-align: end;
	align-items: flex-end;
	gap: 24px;
}
.amountSection .row {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	width: 100%;
	gap: 24px;
}
.amountSection .row.bold {
	font-weight: 700;
}
.amountSection .row .label {
	width: 80px;
}
.amountSection .row .amount {
	text-align: end;
	width: 120px;
}

.notesSection .invoiceInput {
	color: var(--gray-500);
	font-size: 0.875em;
	font-weight: 600;
}
.invoiceInput .selectCustom svg {
	top: 6px;
}
.invoiceInput .options {
	top: 100%;
}
.invoiceInput input:focus,
.invoiceInput textarea:focus {
	-webkit-box-shadow: var(--focus-ring-4px-primary-100);
	box-shadow: var(--focus-ring-4px-primary-100);
	border-color: var(--white);
}
.notesSection .invoiceInput.hiddenTextarea textarea {
	background-color: var(--white);
	border: 1px solid var(--white);
	height: 100px;
}
.notesSection .invoiceInput.hiddenTextarea textarea:hover {
	border: 1px solid var(--disabled);
}
.notesSection .invoiceInput.hiddenTextarea textarea:focus {
	-webkit-box-shadow: var(--focus-ring-4px-primary-100);
	box-shadow: var(--focus-ring-4px-primary-100);
}
.notesSection .invoiceInput .infoText {
	font-weight: 300;
}
.notesSection .invoiceInput .formTextarea {
	background-color: var(--warning-25);
	border-radius: 8px;
	max-width: 100%;
	height: 130px;
	resize: none;
	width: 100%;
}

.bannerSection {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	border-radius: 12px;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	padding: 6px 64px;
	height: 36px;
}
.bannerSection .midSection {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	gap: 8px;
}
.bannerSection.draft {
	background-color: var(--gray-500);
	color: var(--white);
}
.bannerSection.sent {
	background-color: var(--error-600);
	color: var(--white);
}
.bannerSection.paid {
	background-color: var(--success-500);
	color: var(--white);
}
.bannerSection h4 {
	font-weight: 700;
}
.bannerSection p {
	font-weight: 400;
	font-size: 0.875em;
}

.invoicePayments {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	background-color: var(--white);
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	border-radius: 20px;
	max-width: 100%;
	padding: 24px;
	height: 100%;
	width: 100%;
	gap: 20px;
}
.invoicePayments .btnAddCont {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	padding: 24px;
}
.invoicePayments .tabsList {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	width: 100%;
	gap: 8px;
}

/* ## Member Management */
.memberManagementContainer {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-item-align: center;
	-ms-grid-row-align: center;
	align-self: center;
	max-width: 1540px;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	padding: 60px 20px;
	border-radius: 25px;
	background-color: var(--teamBg);
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	width: 100%;
}

.memberManagementContainer .lds-dual-ring {
	margin: 10px auto;
	width: 60px;
	height: 60px;
}

.memberManagementContainer .lds-dual-ring:after {
	width: 36px;
	height: 36px;
}

.memberManagementContainer h2.team {
	margin: 20px 0px 0px 15px;
	line-height: 20px;
	text-align: center;
	color: var(--team);
}
.memberManagementContainer .btn2-medium.team {
	background-color: var(--team);
	color: var(--white);
}
.memberManagementContainer .btn3-primary.team {
	background-color: var(--team);
	color: var(--white);
}

.memberManagementContainer h4 {
	margin: 10px;
}

.memberManagementContainer .header {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: end;
	-ms-flex-align: end;
	align-items: flex-end;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	margin-bottom: 20px;
	max-width: 888px;
	width: 100%;
	gap: 20px;
}
.memberManagementContainer .header .headerIcon {
	-webkit-box-shadow: 1px 1px 6px var(--boxShadowDarkest);
	box-shadow: 1px 1px 6px var(--boxShadowDarkest);
	background-color: var(--team);
	border-radius: 50%;
	fill: var(--white);
	margin-left: 20px;
	padding: 6px;
	cursor: pointer;
	height: 28px;
	width: 28px;
}
.memberManagementContainer .header .headerIcon:hover {
	-webkit-box-shadow: 1px 1px 6px var(--boxShadowDarkBackgroundHover);
	box-shadow: 1px 1px 6px var(--boxShadowDarkBackgroundHover);
}
.memberManagementContainer .header .btnsRight {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
}

.memberManagementContainer .invitationContainer,
.memberManagementContainer .invitationsListContainer {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	width: 100%;
	max-width: 880px;
	padding: 20px;
	background-color: var(--white);
	-webkit-box-shadow: 1px 0px 6px 1px var(--gray);
	box-shadow: 1px 0px 6px 1px var(--gray);
	border-radius: var(--borderRadiusReg);
}

.memberManagementContainer .mainFormCont .formTitle,
.memberManagementContainer .mainFormCont .formLightTitle {
	max-width: 480px;
	width: 100%;
	text-align: left;
}

/* # Invitation */
.invitationContainer {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	min-height: 150px;
}
.invitationContainer .buttonColumn {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	margin: 30px auto;
}

.invitationContainer .invitationForm {
	margin: 10px 0px;
}
.invitationContainer .mainFormCont {
	margin: 0;
}

.invitationContainer .inputRow {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	height: 52px;
	margin: 10px 0px;
}

.invitationContainer h4 {
	margin: 20px;
}

.invitationContainer .btnCont {
	margin-top: auto;
}

.invitationContainer .multiInviteList {
	max-height: 400px;
	width: 100%;
	overflow: auto;
}

.invitationsListContainer h2 {
	padding-bottom: 30px;
	color: var(--team);
	text-align: left;
	width: 100%;
}
.invitationsListContainer h3 {
	word-wrap: break-word;
	font-size: 1em;
}
.invitationsListContainer .colHeaders h4 {
	color: var(--team);
	margin: 0px;
}
.invitationsListContainer .colHeaders h4:nth-child(1),
.invitationsListContainer .invitationsList h3:nth-child(1) {
	width: 20%;
}
.invitationsListContainer .colHeaders h4:nth-child(2),
.invitationsListContainer .invitationsList h3:nth-child(2) {
	width: 40%;
}
.invitationsListContainer .colHeaders h4:nth-child(3),
.invitationsListContainer .invitationsList h3:nth-child(3) {
	width: 10%;
}
.invitationsListContainer .colHeaders h4:nth-child(4),
.invitationsListContainer .invitationsList .btnCont {
	width: 15%;
}
.invitationsListContainer .invitationsList .btnCont {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	gap: 10px;
}
.invitationsListContainer .invitationsList .btnCont button {
	min-width: 100px;
}
.invitationsListContainer .invitationsList {
	max-height: 400px;
	overflow: auto;
	width: 100%;
}
.invitationsListContainer .invitationsList .invite {
	border-bottom: 1px solid var(--gray);
	padding: 10px 0px;
}
.invitationsListContainer .invitationsList .invite:last-child {
	border-bottom: none;
}
.invitationsListContainer .invitationsList .invite,
.invitationsListContainer .colHeaders {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	max-height: 400px;
	-webkit-box-flex: 3;
	-ms-flex-positive: 3;
	flex-grow: 3;
	width: 100%;
	gap: 20px;
}

.permissionsCont {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: flex-start;
	width: 100%;
}
.permissionsCont .col {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: flex-start;
	width: 100%;
	gap: 6px;
}
.permissionsCont .col .checkboxLabel {
	width: 100%;
}

/* # Member list */
.memberList {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	width: 100%;
	max-width: 888px;
	gap: 48px;
	-webkit-box-shadow: 0px 0px 8px var(--boxShadowOrder);
	box-shadow: 0px 0px 8px var(--boxShadowOrder);
}

.memberListItem {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: flex-start;
	min-width: 280px;
	max-width: 420px;
	width: 100%;
	height: 225px;
	word-break: break-all;
	background-color: var(--white);
	border-radius: 16px;
}
.memberListItem .iconShadow {
	margin: 0px 5px;
	-webkit-box-shadow: 0px 0px 6px var(--boxShadowDark);
	box-shadow: 0px 0px 6px var(--boxShadowDark);
	border-radius: 50%;
}
.memberListItem .iconShadow:hover {
	-webkit-box-shadow: 0px 0px 6px var(--boxShadowDarkest);
	box-shadow: 0px 0px 6px var(--boxShadowDarkest);
}

.memberListItem .leftCol {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: flex-start;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	padding: 15px;
	background-color: var(--team-400);
	border-radius: 16px 0px 0px 16px;
	height: 100%;
}
.memberListItem .leftCol .row {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	width: 100%;
}
.memberListItem .leftCol img,
.memberListItem .leftCol .placeholder {
	width: 90px;
	height: 90px;
	border-radius: 50%;
}
.memberListItem .leftCol .placeholder {
	position: relative;
	fill: var(--team);
	z-index: 3;
}
.memberListItem .leftCol .backColor {
	position: absolute;
	background-color: var(--white);
	border-radius: 50%;
	height: 80px;
	width: 80px;
	z-index: 1;
	left: 5px;
	top: 5px;
}

.memberListItem .rightCol {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: flex-start;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	min-width: 120px;
	max-width: 300px;
	width: 100%;
	height: 100%;
	padding: 15px;
	overflow: auto;
}

.memberListItem .rightCol::-webkit-scrollbar {
	display: none;
}
.memberListItem .rightCol .semiBold {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	font-weight: 600;
}
.memberListItem .rightCol .topRow {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: flex-start;
	padding: 15px 5px;
}
.memberListItem .rightCol .bottomRow {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: flex-start;
	padding: 0px 5px;
}

.memberListItem button {
	margin: 10px;
}

/* Trusted Supplier */
.trustedList {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	max-width: 1024px;
	width: 100%;
	gap: 32px;
}
.trustedList .supplier {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	width: 320px;
	max-height: 280px;
	height: 280px;
	-webkit-box-shadow: var(--boxShadowCrslCard);
	box-shadow: var(--boxShadowCrslCard);
	border-radius: 12px;
}
.trustedList .supplierSkel {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	width: 320px;
	margin: 0px 20px;
	-webkit-filter: drop-shadow(2px 2px 6px var(--boxShadowCrslCard));
	filter: drop-shadow(2px 2px 6px var(--boxShadowCrslCard));
	opacity: 0.3;
	border-radius: 12px;
}
.trustedList a {
	text-decoration: none;
	color: var(--black);
}
.trustedList .supplier .supplierHeader,
.trustedList .supplierSkel .supplierHeader {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	background-color: var(--buy-700-main);
	border-radius: 12px 12px 0px 0px;
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	position: relative;
	padding: 12px 20px;
	height: 48px;
	width: 100%;
}
.trustedList .supplier .supplierBtn {
	-webkit-box-shadow: 1px 1px 6px var(--boxShadowDarkest);
	box-shadow: 1px 1px 6px var(--boxShadowDarkest);
	border-radius: var(--borderRadiusBtn);
	padding: 0px 10px;
	color: var(--gray-500);
	font-size: 0.875em;
	cursor: pointer;
	width: 164px;
	height: 36px;
}
.trustedList .supplier .supplierBtn:hover {
	-webkit-box-shadow: 1px 1px 6px var(--boxShadowDarkBackgroundHover);
	box-shadow: 1px 1px 6px var(--boxShadowDarkBackgroundHover);
}
.trustedList .supplier .btnIcon {
	fill: var(--gray-500);
	margin-right: 16px;
	height: 24px;
	width: 24px;
}
.trustedList .supplier .headerIcon {
	fill: var(--success-400);
	height: 24px;
	width: 24px;
}
.trustedList .supplier .trashIcon {
	fill: var(--gray-500);
	height: 20px;
	width: 20px;
}
.trustedList .supplier .headerIcon:hover {
	height: 40px;
	width: 40px;
	border-radius: 25px;
	-webkit-box-shadow: 1px 1px 6px var(--boxShadowDarkBackgroundHover);
	box-shadow: 1px 1px 6px var(--boxShadowDarkBackgroundHover);
}
.trustedList .supplier .supplierHeader h3 {
	margin: 55px 30px 0px 30px;
	font-size: 1.5em;
	text-align: center;
}
.trustedList .supplier .supplierBody,
.trustedList .supplierSkel .supplierBody {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: flex-start;
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	overflow: hidden;
	height: 150px;
	width: 100%;
	padding: 24px 20px 0px 20px;
	background-color: var(--white);
}
.trustedList .supplier .supplierBody h3 {
	height: 60px;
}
.trustedList .supplier .supplierBody p {
	max-height: 62px;
}
.trustedList .supplier .supplierFooter,
.trustedList .supplierSkel .supplierFooter {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	border-radius: 0px 0px 12px 12px;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	padding: 0px 20px;
	height: 80px;
	width: 100%;
	background-color: var(--white);
}

/* Dispatch */
.dispatchCont {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	background-color: var(--teamManageBg);
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-item-align: center;
	-ms-grid-row-align: center;
	align-self: center;
	border-radius: 25px;
	max-width: 1540px;
	padding: 60px 0px;
	margin: 0px auto;
	width: 100%;
}

.packagesCont {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-shadow: var(--boxShadowOrder);
	box-shadow: var(--boxShadowOrder);
	background-color: var(--white);
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	border-radius: 25px;
	-ms-flex-item-align: center;
	-ms-grid-row-align: center;
	align-self: center;
	margin: 0px auto;
	margin: 0px 40px;
	max-width: 800px;
	padding: 40px;
	width: 100%;
}

.packagesList {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	margin: 20px;
	width: 100%;
}

.packagesCols {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	margin: 20px 0px 0px 0px;
	width: 100%;
}

.packageCont {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-shadow: 0px 0px 6px var(--boxShadowDarkest);
	box-shadow: 0px 0px 6px var(--boxShadowDarkest);
	border-radius: 8px;
	margin: 10px 0px;
	padding: 12px 0px;
	width: 100%;
}
.packageCont p {
	margin: 0px 20px;
	font-weight: 600;
	min-width: 10%;
	width: 100px;
}
.packageCont button {
	margin: 0px 10px;
}
.packageCont .btnCont {
	min-width: 10%;
	margin: 0px 20px;
	max-width: 20%;
	width: 100px;
}

.packagesCont .btnCont {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}
.packagesCols h4 {
	width: 100px;
	min-width: 10%;
	margin: 0px 20px;
}

/* Package details */
.packageDetailsCont {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	height: 100%;
	width: 100%;
}
.packageDetailsCont h2 {
	margin: 30px 0px 10px 0px;
}
.packageDetailsCont .dataCont {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
}
.packageDetailsCont .dataCont .row {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.packageDetailsCont .dataCont .rowReverse {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: reverse;
	-ms-flex-direction: row-reverse;
	flex-direction: row-reverse;
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.packageDetailsCont .dataCont .row p {
	margin-left: 20px;
}
.packageDetailsCont .dataCont .row h4 {
	width: 80px;
}
.packageDetailsCont .cols {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.packageDetailsCont .cols h4 {
	width: 200px;
}
.packageDetailsCont .cols h4:last-child {
	width: 40px;
}

.packageDetailsCont .orderItems {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.packageDetailsCont .orderItems .message {
	-ms-flex-item-align: start;
	align-self: flex-start;
	width: 400px;
	margin: 20px 0px;
	font-size: 1.125em;
}
.packageDetailsCont .orderItems .row {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	margin: 10px 0px;
	width: 100%;
}
.packageDetailsCont .orderItems .row p {
	width: 25%;
}
.packageDetailsCont .orderItems .row .btnRow {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	width: 40px;
}

/* Addresses */
.addressesContainer {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	width: 100%;
}
.addressesContainer .addressEditBtn {
	position: absolute;
	right: 6px;
}

.addressTitle {
	margin: 40px auto 20px auto;
	text-align: center;
}

.addressesContainer button {
	min-width: 100px;
	margin: 0px 10px;
}

.regAddressCont {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}
.regAddressCont .regAddressItem {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: flex-start;
	-webkit-box-shadow: 1px 0px 6px 1px var(--gray);
	box-shadow: 1px 0px 6px 1px var(--gray);
	background-color: var(--white);
	border-radius: var(--borderRadiusReg);
	height: 100px;
	width: 250px;
	margin: 20px 10px;
	padding: 20px;
}

.addressManagementContainer {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	background-color: var(--teamBg);
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	border-radius: 25px;
	max-width: 1540px;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	padding: 40px;
	width: 100%;
	gap: 26px;
}

.regAddressCont .addressItem .leftCol,
.addressManagementContainer .addressItem .leftCol {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: flex-start;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	width: 100%;
	min-width: 200px;
	padding: 20px;
	height: 100%;
	gap: 3px;
}

.regAddressCont .addressItem .leftCol h4,
.addressManagementContainer .addressItem .leftCol h4 {
	font-size: 1em;
}

.addressManagementContainer .addressItem,
.addressManagementContainer .addAddress,
.regAddressCont .addressItem {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-shadow: 1px 0px 6px 1px var(--gray);
	box-shadow: 1px 0px 6px 1px var(--gray);
	background-color: var(--white);
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	border-radius: 16px;
	max-width: 95vw;
	min-height: 175px;
	max-height: 175px;
	height: 100%;
	width: 468px;
}

.addressManagementContainer .addAddress:hover {
	cursor: pointer;
	-webkit-box-shadow: 1px 0px 6px 1px var(--black);
	box-shadow: 1px 0px 6px 1px var(--black);
}

.addressManagementContainer .addressItem h4,
.regAddressCont .addressItem h4 {
	width: 100%;
}

.addressManagementContainer .addressItem .addressButtons,
.regAddressCont .addressItem .addressButtons {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	border-radius: 0px 16px 16px 0px;
	background-color: var(--team);
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	padding: 20px;
	height: 100%;
	min-width: 200px;
	gap: 12px;
}

.addressManagementContainer .addressItem .addressButtons .functionalButtonSecondary,
.regAddressCont .addressItem .addressButtons .functionalButtonSecondary {
	min-width: 125px;
}

/* FormBuilder */
.docketBuildCont {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-item-align: center;
	-ms-grid-row-align: center;
	align-self: center;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: flex-start;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	background-color: var(--gray-300);
	border-radius: 20px;
	padding: 70px 0px;
	max-width: 1540px;
	width: 100%;
	min-height: 80vh;
}
.docketBuildCont::-webkit-scrollbar {
	display: none;
}

.docketBuildCont span {
	margin: 0px;
}

.docketBuildCont .headerIcon {
	fill: var(--white);
	border-radius: 50%;
	line-height: 28px;
	cursor: pointer;
	padding: 8px;
	height: 24px;
	width: 24px;
	margin: 8px;
}
.docketBuildCont .headerIcon:hover {
	-webkit-box-shadow: 0px 0px 4px var(--boxShadowDarkest);
	box-shadow: 0px 0px 4px var(--boxShadowDarkest);
}
.docketBuildCont .docketIcon {
	height: 24px;
	width: 24px;
	fill: var(--gray);
}
.docketBuildCont .field .docketIcon {
	height: 24px;
	width: 24px;
}
.docketBuildCont .field .docketIcon.large {
	height: 36px;
	width: 36px;
	fill: var(--black);
}
.docketBuildCont .docketIcon.large {
	height: 36px;
	width: 36px;
	fill: var(--white);
}

.docketBuildCont .btn2-medium.turq {
	color: var(--turqMain);
}
.docketBuildCont .toolHeader {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	background-color: var(--gray-65);
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	margin-bottom: 24px;
	border-radius: 16px;
	min-height: 100px;
	max-width: 450px;
	padding: 6px 0px;
	width: 100%;
	gap: 10px;
}
.docketBuildCont .toolHeader span {
	line-height: 28px;
	color: var(--white);
	font-size: 1.5em;
	font-weight: 700;
	padding: 16px;
}
.docketBuildCont .fixedToolsCont {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	position: sticky;
	position: -webkit-sticky;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	max-width: 450px;
	width: 100%;
	top: 110px;
}

.docketBuildCont .toolIcon {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	border-radius: 50%;
	fill: var(--gray);
	cursor: pointer;
	height: 20px;
	width: 20px;
}
.docketBuildCont .toolIcon:hover {
	-webkit-box-shadow: 0px 0px 4px var(--boxShadowDarkest);
	box-shadow: 0px 0px 4px var(--boxShadowDarkest);
}

.docketBuildCont .leftIcon {
	position: absolute;
	cursor: pointer;
	height: 24px;
	width: 24px;
	padding: 3px;
	fill: var(--gray);
	right: 0px;
	bottom: 3px;
	-webkit-box-shadow: 0px 0px 4px var(--boxShadowDarkest);
	box-shadow: 0px 0px 4px var(--boxShadowDarkest);
	border-radius: 50%;
}
.docketBuildCont h4 {
	margin: 20px 10px;
	text-align: center;
}
.docketBuildCont .col {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	border-radius: 16px;
	background-color: var(--white);
}
.docketBuildCont .col .colTabs {
	cursor: pointer;
}
.docketBuildCont .col .colTabs.selected {
	color: var(--turqMain);
}
.docketBuildCont .toolTop {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	border-radius: 8px 8px 0px 0px;
	background-color: var(--white);
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	padding: 16px 16px 60px 16px;
	width: 100%;
	z-index: 1;
	gap: 3px;
}
.docketBuildCont .toolTop .flexRow,
.docketBuildCont .toolTop .flexRow .btn {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	max-width: 100%;
	min-width: 20px !important;
}

.docketBuildCont .tabs {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	position: absolute;
	background-color: var(--gray-500);
	border: 1px solid var(--gray-300);
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	border-radius: 8px;
	max-width: 420px;
	bottom: -22.5px;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	padding: 4px;
	right: 10px;
	left: 10px;
	gap: 3px;
}

.docketBuildCont .tabs.edit {
	background-color: var(--gray-300);
	border: 1px solid var(--white);
}
.docketBuildCont .tabs.edit .tab {
	background-color: var(--gray-300);
	border: 1px solid var(--gray-300);
	color: var(--gray-500);
}
.docketBuildCont .tabs.edit .tab.selected {
	background-color: var(--white);
	border: 1px solid var(--white);
}

.docketBuildCont .tabs .tab {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	font-weight: 700;
	-webkit-user-select: none;
	-ms-user-select: none;
	-moz-user-select: none;
	user-select: none;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	color: var(--gray-300);
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	border-radius: 4px;
	fill: var(--white);
	font-size: 0.875em;
	cursor: pointer;
	padding: 9px;
	height: 36px;
	-webkit-box-flex: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	gap: 6px;
}
.docketBuildCont .tabs .tab.selected {
	background-color: var(--team-sect-bg);
	color: var(--gray-800);
	height: 36px;
}

.docketBuildCont .col .dropSection {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	padding: 0px 0px 100px 0px;
	margin-top: 20px;
	width: 90%;
}
.docketBuildCont .col .dropSection .placeholder {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	height: 100px;
	width: 100%;
	margin-top: 20px;
	color: var(--gray);
}
.docketBuildCont .col .dropSection.container {
	border: 1px dotted var(--gray);
	padding: 0px 0px 30px 0px;
	width: 550px;
}
.docketBuildCont .col.docket {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	max-height: 100%;
	margin: 0px 30px;
	max-width: 680px;
	width: 100%;
}
.docketBuildCont .col.docket .backBtn {
	position: absolute;
	top: -44px;
	left: 0px;
}
.docketBuildCont .col.docket.disabled {
	background-color: var(--disabled);
}
.docketBuildCont .col.library {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	border-radius: 0px 0px 16px 16px;
	background-color: var(--gray-500);
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	overflow-x: hidden;
	overflow-y: auto;
	max-width: 450px;
	width: 100%;
}
.docketBuildCont .col.library .dropSection {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-align: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	padding: 0px 12px 12px 12px;
	margin-top: 36px;
	max-width: 450px;
	width: 100%;
	gap: 12px;
}

.docketBuildCont .col .field .dragHandle {
	height: 24px;
	width: 24px;
}
.docketBuildCont .col .field {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-shadow: 1px 1px 6px var(--boxShadowDarkest);
	box-shadow: 1px 1px 6px var(--boxShadowDarkest);
	background-color: var(--white);
	padding: 3px 3px 10px 3px;
	border-radius: 8px;
	font-size: 0.875em;
	min-height: 100px;
	min-width: 134px;
	max-height: 100px;
	max-width: 134px;
	font-weight: 700;
}
.docketBuildCont .col .field .fieldText {
	text-align: center;
}

.docketBuildCont .col .containerField {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	width: 100%;
}

.docketBuildCont .col .fieldPre {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	max-width: 550px;
	width: 100%;
}
.docketBuildCont .col .fieldPre:hover {
	-webkit-box-shadow: 1px 1px 6px var(--boxShadowDarkBackgroundHover);
	box-shadow: 1px 1px 6px var(--boxShadowDarkBackgroundHover);
	border-radius: 8px;
}

.docketBuildCont .col .fieldPre .fieldTools {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	position: absolute;
	cursor: pointer;
	fill: var(--gray);
	padding: 6px;
	bottom: 24px;
	height: 30px;
	z-index: 8;
	right: 0px;
	gap: 6px;
}
.docketBuildCont .col .fieldPre .fieldTools.top {
	bottom: auto;
	top: 0px;
}
.docketBuildCont .col .fieldPre .formRowCenter,
.docketBuildCont .col .fieldPre .formColumnCenter {
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: flex-start;
}
.docketBuildCont .col .fieldPre .formRowCenter .extraBold {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	min-width: 200px;
	max-width: 200px;
}

.docketBuildCont .col .fieldPre .header,
.docketBuildCont .col .fieldPre .paragraph {
	margin: 30px auto;
	max-width: 500px;
	text-align: left;
}

.docketBuildCont .editBoxCont {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-shadow: 0px 0px 12px var(--boxShadowDarkest);
	box-shadow: 0px 0px 12px var(--boxShadowDarkest);
	background-color: var(--purchaseBgVeryLight);
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	padding-bottom: 20px;
	border-radius: 8px;
	max-width: 500px;
	margin-top: 20px;
	width: 100%;
}
.docketBuildCont .editBoxCont .tabs {
	left: 35px;
}
.docketBuildCont .editBoxCont .fileInput {
	width: 320px;
}
.docketBuildCont .editBoxCont .headerCont {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	background-color: var(--white);
	border-radius: 8px 8px 0px 0px;
	margin-bottom: 20px;
	min-height: 56px;
	width: 100%;
}
.docketBuildCont .editBoxCont .headerCont p {
	padding: 0px 24px 40px 24px;
}
.docketBuildCont .editBoxCont .headerInner {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	padding: 12px 12px 0px 24px;
	margin-bottom: 20px;
	min-height: 56px;
	width: 100%;
	gap: 15px;
}
.docketBuildCont .editBoxCont .headerInner h4 {
	margin: 0px;
}
.docketBuildCont .editBoxCont .editBody {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	width: 100%;
	padding: 20px 0px 0px 0px;
}

.docketBuildCont .editBoxCont .formRowCenter,
.docketBuildCont .editBoxCont .formRowTop {
	max-width: 320px;
	margin: 5px 0px;
}
.docketBuildCont .editBoxCont .docketIcon.large {
	fill: var(--black);
}
.docketBuildCont .editBoxCont .docketIcon.plus {
	-webkit-box-shadow: 1px 1px 6px var(--boxShadowDarkest);
	box-shadow: 1px 1px 6px var(--boxShadowDarkest);
	margin-bottom: 20px;
	border-radius: 50%;
	cursor: pointer;
	height: 24px;
	width: 24px;
}
.docketBuildCont .editBoxCont .docketIcon.save {
	fill: var(--turqMain);
}
.docketBuildCont .editBoxCont .docketIcon.save,
.docketBuildCont .editBoxCont .docketIcon.manage,
.docketBuildCont .editBoxCont .docketIcon.trash {
	-webkit-box-shadow: 1px 1px 6px var(--boxShadowDarkest);
	box-shadow: 1px 1px 6px var(--boxShadowDarkest);
	border-radius: 50%;
	cursor: pointer;
	padding: 8px;
	height: 24px;
	width: 24px;
}
.docketBuildCont .editBoxCont .docketIcon.plus:hover,
.docketBuildCont .editBoxCont .docketIcon.save:hover,
.docketBuildCont .editBoxCont .docketIcon.manage:hover,
.docketBuildCont .editBoxCont .docketIcon.trash:hover,
.docketBuildCont .editBoxCont .docketIcon.close:hover {
	-webkit-box-shadow: 1px 1px 6px var(--boxShadowDarkBackgroundHover);
	box-shadow: 1px 1px 6px var(--boxShadowDarkBackgroundHover);
}

.docketBuildCont .editBoxCont .docketIcon.close {
	border-radius: 50%;
	fill: var(--gray);
	cursor: pointer;
	padding: 8px;
	height: 24px;
	width: 24px;
}

.docketBuildCont .fieldPre .graphicImg {
	border-radius: 6px;
	height: 150px;
	width: 250px;
	margin: 20px auto;
	-o-object-fit: contain;
	object-fit: contain;
}

.docketBuildCont .inputContainer,
.docketBuildCont .PhoneInput,
.docketBuildCont .selectCustom,
.docketBuildCont .formTextarea {
	max-width: 360px;
}

/** TEAM PROFILE */
.teamProfileCont {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	background-color: var(--white);
	padding: 60px 4% 20px 4%;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	border-radius: 25px;
	max-width: 1540px;
	min-width: 260px;
	width: 100%;
}
.teamProfileCont .mapCont {
	border-radius: 0px 0px 10px 10px;
	overflow: hidden;
	height: 100%;
	width: 100%;
}
.teamProfileCont .mapPinIcon {
	fill: var(--warningRed);
	height: 40px;
	width: 40px;
}
.teamProfileCont .pinText {
	position: absolute;
	-webkit-box-shadow: 1px 1px 6px var(--boxShadowDarkest);
	box-shadow: 1px 1px 6px var(--boxShadowDarkest);
	background-color: rgba(241, 241, 241, 0.779);
	fill: var(--warningRed);
	border-radius: 0px 8px 8px 8px;
	font-size: 1.2em;
	font-weight: 600;
	padding: 6px 12px;
	min-width: 150px;
	left: 25px;
	top: 40px;
}
.teamProfileCont .topRow,
.teamProfileCont .midRow,
.teamProfileCont .botRow {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	margin-bottom: 40px;
	max-width: 1400px;
	width: 100%;
	gap: 40px;
}
.teamProfileCont .cardTitle {
	-ms-flex-item-align: start;
	align-self: flex-start;
	margin-bottom: 6px;
}
.teamProfileCont .midRow .thinCard {
	height: 520px;
}
.teamProfileCont .botRow .thinCard {
	height: 300px;
	overflow-y: auto;
}
.teamProfileCont .cardRow {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	width: 100%;
	padding: 12px;
	border-radius: var(--borderRadiusReg) var(--borderRadiusReg) 0px 0px;
	background-color: var(--white);
}
.teamProfileCont .thinCard {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-box-shadow: var(--boxShadowShop);
	box-shadow: var(--boxShadowShop);
	border-radius: 16px;
	width: 420px;
}
.teamProfileCont .midRow .thinCard:nth-child(1) {
	background-color: var(--purchaseBgLight);
}
.teamProfileCont .midRow .thinCard:nth-child(2) {
	width: 480px;
	padding: 12px 20px 20px 20px;
}
.teamProfileCont .botRow .thinCard {
	padding: 12px 20px 20px 20px;
}
.teamProfileCont .thickCard {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: flex-start;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-box-shadow: var(--boxShadowShop);
	box-shadow: var(--boxShadowShop);
	border-radius: 16px;
	height: 300px;
	width: 940px;
}
.teamProfileCont .thickCard .cardRow {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	width: 98%;
	padding: 20px;
	height: 60px;
	border-radius: 0px;
	border-bottom: 1px solid var(--purchaseBgLight);
	background-color: unset;
}
.teamProfileCont .cardCol {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	justify-self: flex-start;
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: flex-start;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	margin: 0px 12px;
	width: 100%;
	gap: 10px;
}
.teamProfileCont .cardCol p {
	margin: 0px;
}
.teamProfileCont .cardCol .teamData {
	font-weight: 600;
	font-size: 1em;
	margin: 0px;
}
.teamProfileCont .suppIcon {
	height: 28px;
	width: 28px;
	min-width: 28px;
	padding: 6px;
	fill: var(--purchase);
	-ms-flex-item-align: end;
	align-self: flex-end;
	margin-bottom: 15px;
	margin-left: auto;
	cursor: pointer;
	-webkit-box-shadow: 1px 1px 6px var(--boxShadowDarkest);
	box-shadow: 1px 1px 6px var(--boxShadowDarkest);
	border-radius: 50%;
}
.teamProfileCont .suppIcon:hover {
	-webkit-box-shadow: 1px 1px 6px var(--boxShadowDarkBackgroundHover);
	box-shadow: 1px 1px 6px var(--boxShadowDarkBackgroundHover);
}
.teamProfileCont .suppIcon.disabled {
	height: 28px;
	width: 28px;
	min-width: 28px;
	fill: var(--successGreen);
	-ms-flex-item-align: end;
	align-self: flex-end;
	margin-bottom: 15px;
	margin-left: auto;
	border-radius: 50%;
	-webkit-box-shadow: none;
	box-shadow: none;
	cursor: default;
	padding: 6px;
}
.teamProfileCont .suppIconReg {
	min-height: 24px;
	min-width: 24px;
	max-height: 24px;
	max-width: 24px;
	-ms-flex-item-align: start;
	align-self: flex-start;
	margin-right: 10px;
}
.teamProfileCont .btnIcon {
	height: 36px;
	width: 36px;
	fill: var(--black);
	margin: auto 10px auto 2px;
}
.teamProfileCont .shopBadgesCont {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	box-sizing: border-box;
	padding: 28px;
	max-width: 100%;
	width: 300px;
	gap: 24px;
}
.teamProfileCont .lmParagraph {
	margin: 0px 28px;
}
.teamProfileCont .shopBadgesCont .badge {
	width: 52px;
}
.teamProfileCont .shopBadgesCont .badge p {
	text-align: center;
	font-size: 0.625em;
	color: var(--gray);
	font-weight: 700;
}
.teamProfileCont .teamIcon {
	height: 60px;
	width: 60px;
	min-height: 60px;
	min-width: 60px;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	font-size: 1.4em;
}
.teamProfileCont .verifiedIcon {
	height: 52px;
	width: 52px;
	min-height: 52px;
	min-width: 52px;
}
.teamProfileCont .caretDownIcon {
	margin-right: -18px;
}
.teamProfileCont .teamName {
	color: var(--purchase);
}
.teamProfileCont .teamSub {
	color: var(--gray);
	font-size: 0.875em;
}
.teamProfileCont .teamHead {
	-ms-flex-item-align: start;
	align-self: flex-start;
	font-weight: 600;
	margin: 0px 0px 20px 0px;
}
.teamProfileCont .teamDesc {
	-ms-flex-item-align: start;
	align-self: flex-start;
	margin: 0px 0px 20px 0px;
	overflow: hidden;
}
.teamProfileCont .teamDesc.hideTextOverflow {
	height: 100%;
	word-wrap: break-word;
	background: -webkit-linear-gradient(#333, #333 80%, #eee 100%);
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}
.teamProfileCont .thinCard h5 {
	cursor: pointer;
}
.teamProfileCont .thinCard h5:hover {
	color: var(--turqMain);
}
.teamProfileCont .boldText {
	justify-self: flex-start;
	font-weight: 600;
}
.teamProfileCont .btnCont {
	margin-bottom: 0px;
	margin-top: auto;
}
.teamProfileCont .btnCont button {
	margin: 0px;
}
.teamProfileCont .filesCont {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: flex-start;
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	width: 100%;
}
.teamProfileCont .galleryCont {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: flex-start;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	box-sizing: border-box;
	flex-direction: row;
	-ms-flex-wrap: wrap;
	padding: 0px 28px;
	flex-wrap: wrap;
	max-width: 100%;
	width: 100%;
}
.teamProfileCont .galleryImg {
	border-radius: 3px;
	-o-object-fit: cover;
	object-fit: cover;
	overflow: hidden;
	cursor: pointer;
	height: 100px;
	width: 100px;
	margin: 6px;
}
.teamProfileCont .fileRow {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-ms-flex-item-align: start;
	align-self: flex-start;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.teamProfileCont .fileRow:hover {
	color: var(--turqMain);
	cursor: pointer;
}

/* Purchase Orders */
.purchaseOrdersCont {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-item-align: center;
	-ms-grid-row-align: center;
	align-self: center;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	background-color: var(--white);
	max-width: 1540px;
	border-radius: 16px;
	padding: 20px;
	width: 100%;
}
.purchaseOrdersList {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-ms-flex-item-align: center;
	-ms-grid-row-align: center;
	align-self: center;
	border-radius: 16px;
	max-width: 1000px;
	height: 100%;
	width: 100%;
	padding: 20px;
}
.purchaseOrdersList .colRow {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	gap: 10px;
	width: 100%;
}
.purchaseOrdersList .colRow .rowItem {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	min-height: 32px;
	width: 200px;
}
.purchaseOrdersList .orderItem {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: flex-start;
	gap: 12px;
	width: 100%;
	border-bottom: 1px solid var(--gray);
	margin-bottom: 12px;
	padding-bottom: 12px;
}
.purchaseOrdersList .orderItem .orderData {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	width: 100%;
}
.purchaseOrdersList .orderItem .orderData .underline:hover {
	color: var(--turqMain);
}

.billIcon {
	position: absolute;
	cursor: pointer;
	right: 0px;
	height: 24px;
	width: 24px;
	padding: 3px;
	border-radius: 50%;
	-webkit-box-shadow: 1px 1px 6px var(--boxShadowDarkest);
	box-shadow: 1px 1px 6px var(--boxShadowDarkest);
}
.billIcon:hover {
	-webkit-box-shadow: 1px 1px 6px var(--boxShadowDarkBackgroundHover);
	box-shadow: 1px 1px 6px var(--boxShadowDarkBackgroundHover);
}

/** Files */
.filesList {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-ms-grid-columns: 1fr 46px 1fr 46px 1fr;
	grid-template-columns: repeat(3, 1fr);
	-ms-grid-rows: 1fr 24px 1fr 24px 1fr 24px 1fr 24px 1fr;
	grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
	background-color: var(--gray-300);
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	border-radius: 24px;
	grid-gap: 24px 46px;
	padding: 40px 24px;
	max-width: 100%;
	width: 100%;
}
.filesList > *:nth-child(1) {
	-ms-grid-row: 1;
	-ms-grid-column: 1;
}
.filesList > *:nth-child(2) {
	-ms-grid-row: 1;
	-ms-grid-column: 3;
}
.filesList > *:nth-child(3) {
	-ms-grid-row: 1;
	-ms-grid-column: 5;
}
.filesList > *:nth-child(4) {
	-ms-grid-row: 3;
	-ms-grid-column: 1;
}
.filesList > *:nth-child(5) {
	-ms-grid-row: 3;
	-ms-grid-column: 3;
}
.filesList > *:nth-child(6) {
	-ms-grid-row: 3;
	-ms-grid-column: 5;
}
.filesList > *:nth-child(7) {
	-ms-grid-row: 5;
	-ms-grid-column: 1;
}
.filesList > *:nth-child(8) {
	-ms-grid-row: 5;
	-ms-grid-column: 3;
}
.filesList > *:nth-child(9) {
	-ms-grid-row: 5;
	-ms-grid-column: 5;
}
.filesList > *:nth-child(10) {
	-ms-grid-row: 7;
	-ms-grid-column: 1;
}
.filesList > *:nth-child(11) {
	-ms-grid-row: 7;
	-ms-grid-column: 3;
}
.filesList > *:nth-child(12) {
	-ms-grid-row: 7;
	-ms-grid-column: 5;
}
.filesList > *:nth-child(13) {
	-ms-grid-row: 9;
	-ms-grid-column: 1;
}
.filesList > *:nth-child(14) {
	-ms-grid-row: 9;
	-ms-grid-column: 3;
}
.filesList > *:nth-child(15) {
	-ms-grid-row: 9;
	-ms-grid-column: 5;
}
.filesList .fileCont {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	position: relative;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	background-color: var(--white);
	border-radius: var(--borderRadiusReg);
	-webkit-box-shadow: var(--shadow-lg);
	box-shadow: var(--shadow-lg);
	max-height: 200px;
	max-width: 500px;
	padding: 20px;
	-webkit-box-flex: 1;
	-ms-flex: 1;
	flex: 1;
}
.filesList .fileCont .col {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	max-width: 100%;
	height: 100%;
}
.filesList .fileCont .iconCont {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	fill: var(--gray-500);
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-shadow: none;
	box-shadow: none;
	height: 32px;
	width: 32px;
}
.filesList .fileCont .btnCont {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	margin-top: 0px;
	gap: 18px;
}
.filesList .fileCont .textCont {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	padding-left: 20px;
}
.filesList .fileCont .textCont .textRow {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	gap: 12px;
}
.filesList .fileCont .textCont .filename {
	word-wrap: break-word;
	margin-bottom: 12px;
	max-width: 200px;
	font-weight: 700;
	font-size: 1em;
	width: 100%;
}
.filesList .fileCont .textCont p {
	font-size: 0.875em;
	margin: 0px;
	width: 50%;
}
.filesList .fileCont .iconCont:hover {
	-webkit-box-shadow: 1px 1px 6px var(--boxShadowDarkest);
	box-shadow: 1px 1px 6px var(--boxShadowDarkest);
}
.filesList .fileCont .col:nth-child(1) {
	width: 160px;
}
.filesList .fileCont .col:nth-child(2) {
	width: 100%;
}
.filesList .fileCont .thumb {
	border-radius: 8px;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	overflow: hidden;
	height: 160px;
	width: 160px;
}
.filesList .fileCont .thumbPlaceholder {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	background-color: var(--primary-100);
	border-radius: 8px;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	padding: 12px 24px;
	height: 160px;
	fill: var(--primary-700);
	width: 160px;
}
.filesList .fileCont .thumbPlaceholder svg {
	height: 80px;
}

/* Invoices */
.invoicesCont {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	width: 200px;
}
.invoicesCont h4 {
	margin: 0px;
}
.invoicesCont .flexRow {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	width: 200px;
}
.invoicesCont .flexCol .flexRow {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	width: 200px;
}

/* Manage General */
.manageCont {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	background-color: var(--white);
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	border-radius: 12px;
	width: 100%;
	gap: 24px;
}
.manageCont h2 {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	color: var(--apps-600-main);
	line-height: 24px;
	gap: 15px;
}
.manageCont .manageIcon {
	margin-top: -3px;
	fill: var(--apps-600-main);
	height: 28px;
	width: 28px;
}
.manageCont .smallHeader {
	margin: 0px;
	color: var(--apps-900);
	font-size: 1.125em;
}
.manageCont .manageHeader {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	padding: 8px 24px 0px 24px;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	margin-bottom: 12px;
}
.manageCont .manageHeader p {
	max-width: 70%;
}
.manageCont .manageHeader.small {
	font-size: 1em;
}
.manageCont .manageRow {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	background-color: var(--gray-300);
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: flex-start;
	border-radius: var(--borderRadiusCard);
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	padding: 40px 24px;
	min-height: 280px;
	gap: 36px;
}
.manageCont .manageRow.thin {
	padding: 0px 24px;
}
.manageCont .manageRow.thin .rowCol {
	min-height: 80px;
}
.manageCont .apps .manageRow {
	margin: 30px 24px;
	padding: 0px;
}
.manageCont .apps {
	background-color: var(--teamBgVeryBright);
	border-radius: 12px;
}
.manageCont .item {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	position: relative;
	background-color: var(--white);
	-webkit-box-shadow: var(--shadow-xl);
	box-shadow: var(--shadow-xl);
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	border-radius: 8px;
	cursor: pointer;
	padding: 20px;
	height: 200px;
	width: 200px;
	gap: 14px;
}
.manageCont .item:hover {
	-webkit-box-shadow: var(--shadow-btnhover);
	box-shadow: var(--shadow-btnhover);
}
.manageCont .item .manageIconLarge {
	fill: var(--apps-900);
	max-height: 90px;
	max-width: 100%;
	margin: auto;
}
.manageCont .manageRow .rowItems {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	width: 100%;
	gap: 36px;
}
.manageCont .manageRow .leftDesc {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: flex-start;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	min-height: 200px;
	max-width: 200px;
	width: 30%;
}
.manageCont .manageRow .leftDesc p {
	font-size: 0.75em;
	margin-top: 24px;
	color: var(--apps-900);
}
.manageCont h3 {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	gap: 12px;
}
.manageCont .tempDesc {
	color: var(--gray);
	font-size: 0.75em;
}
.manageCont .headerDesc {
	color: var(--black);
	font-size: 1em;
	max-width: 350px;
}

/* Apps */
.teamAppsCont {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	background-color: var(--white);
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	border-radius: 12px;
	width: 100%;
	gap: 24px;
}
.teamAppsCont h2 {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	color: var(--apps-600-main);
	line-height: 24px;
	gap: 15px;
}
.teamAppsCont .appIcon {
	margin-top: -3px;
	fill: var(--apps-600-main);
	height: 28px;
	width: 28px;
}
.teamAppsCont .smallHeader {
	margin: 0px;
	color: var(--apps-900);
	font-size: 1.125em;
}
.teamAppsCont .appsHeader {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	padding: 10px 24px 0px 24px;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	margin-bottom: 12px;
}
.teamAppsCont .appsHeader p {
	max-width: 70%;
}
.teamAppsCont .appsHeader.small {
	font-size: 1em;
}
.teamAppsCont .appsRow {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	background-color: var(--gray-200);
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: flex-start;
	border-radius: var(--borderRadiusCard);
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	padding: 40px 24px;
	min-height: 280px;
	gap: 36px;
}
.teamAppsCont .appsRow.thin {
	padding: 0px 24px;
}
.teamAppsCont .appsRow.thin .rowCol {
	min-height: 80px;
}
.teamAppsCont .apps .appsRow {
	margin: 30px 24px;
	padding: 0px;
}
.teamAppsCont .apps {
	background-color: var(--teamBgVeryBright);
	border-radius: 12px;
}
.teamAppsCont .appItem {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	position: relative;
	-webkit-box-shadow: var(--shadow-xl);
	box-shadow: var(--shadow-xl);
	background-color: var(--white);
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	border-radius: 8px;
	padding: 20px;
	height: 200px;
	width: 200px;
	gap: 14px;
}
.teamAppsCont .appItem .appLogo {
	margin: auto;
	max-height: 90px;
	max-width: 100%;
}
.teamAppsCont .appsRow .rowItems {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	width: 100%;
	gap: 36px;
}
.teamAppsCont .appsRow .leftDesc {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: flex-start;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	min-height: 200px;
	max-width: 200px;
	width: 30%;
}
.teamAppsCont .appsRow .leftDesc p {
	color: var(--apps-900);
	font-size: 0.75em;
	margin-top: 24px;
}
.teamAppsCont h3 {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	gap: 12px;
}
.teamAppsCont .tempDesc {
	color: var(--gray);
	font-size: 0.75em;
}
.teamAppsCont .headerDesc {
	color: var(--black);
	font-size: 1em;
	max-width: 350px;
}
.teamAppsCont .appsIcon {
	fill: var(--black);
	height: 32px;
	width: 32px;
}

.appsRow.recommended {
	background-color: var(--gray-25);
	border-radius: 0px 0px 12px 12px;
}

/* App Manage */
.appManageCont {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: flex-start;
	margin: 0px auto 20px auto;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	border-radius: 24px;
	max-width: 1540px;
	padding: 0px 20px;
	width: 100%;
}
.appManageCont .manageForm {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: flex-start;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	width: 100%;
}
.appManageCont .manageForm.thin .avatar {
	border-radius: 6px;
}
.appManageCont .manageForm.thin .section {
	flex-direction: row !important;
	max-width: 984px;
	gap: 24px;
}
.appManageCont .manageForm.thin .section .col {
	flex-grow: 1;
}
.appManageCont .manageForm.thin .formRowCenter {
	color: var(--black) !important;
	margin: 0px;
}
.appManageCont .manageForm .section {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: flex-start;
	max-width: 650px;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	width: 100%;
	gap: 6px 9px;
}
.appManageCont .manageForm .section .formRowCenter {
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: flex-start;
	color: var(--apps-700);
	padding: 0px;
}
.appManageCont h4,
.appManageCont h2,
.appManageCont .smallHeader {
	margin: 12px 0px;
}
.appManageCont .mainHeader {
	justify-content: space-between;
}
.appManageCont .mainHeader h1 {
	font-size: 1.5em;
}
.appManageCont .headerCont {
	display: flex;
	justify-content: space-between;
	width: 100%;
}
.appManageCont .col {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: flex-start;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	max-width: 100%;
	width: 300px;
	gap: 6px;
}
.appManageCont .col .checkboxLabel {
	width: 100%;
}
.suggestion {
	-webkit-box-shadow: 0px 0px 4px var(--boxShadowDarkest);
	box-shadow: 0px 0px 4px var(--boxShadowDarkest);
	border-radius: 6px;
	padding: 6px 12px;
	cursor: pointer;
}
.suggestion:hover {
	-webkit-box-shadow: 0px 0px 4px var(--boxShadowDarkBackgroundHover);
	box-shadow: 0px 0px 4px var(--boxShadowDarkBackgroundHover);
}
.suggestDesc {
	margin: 0px 0px 12px 0px;
	color: var(--apps-700);
}

/* App Details */
.appDetails .thinCol {
	min-width: 15%;
}
.appDetails .wideCol {
	min-width: 70%;
}
.appDetails .wideCol,
.appDetails .thinCol {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: flex-start;
	background-color: var(--white);
	border-radius: 8px;
	padding: 20px;
	height: 100%;
	gap: 30px;
}
.appDetails .largeImg {
	border: 1px solid var(--gray);
	border-radius: 8px;
	max-width: 80%;
	padding: 12px;
}
.appDetails .logoImg {
	max-width: 200px;
	width: 100%;
}
.appDetails button,
.appDetails .addBtn {
	width: 160px;
}
.appDetails p {
	font-weight: 600;
}
.appDetails .backLink {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	gap: 20px;
}
.backIcon {
	fill: var(--turqMain);
}
.appDetails .backLink:hover {
	color: var(--turqMain);
}
.appDetails .backIcon {
	max-width: 12px;
}

/** Field suggestion */
.suggestionsCont {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	max-width: 400px;
	margin: 20px 0px;
	padding: 0px 20px;
	gap: 6px;
}
.suggestionsCont .suggestion {
	-webkit-box-shadow: 0px 0px 4px var(--boxShadowDarkest);
	box-shadow: 0px 0px 4px var(--boxShadowDarkest);
	background-color: var(--white);
	border-radius: 6px;
	padding: 6px 12px;
	cursor: pointer;
}
.suggestionsCont .suggestion:hover {
	-webkit-box-shadow: 0px 0px 4px var(--boxShadowDarkBackgroundHover);
	box-shadow: 0px 0px 4px var(--boxShadowDarkBackgroundHover);
}

/** Docket Template Library */
.libraryCont {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	background-color: var(--white);
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	border-radius: 12px;
	margin: 0px 70px;
	width: 100%;
	gap: 60px;
}
.libraryCont .librarySection {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	background-color: var(--gray-300);
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	border-radius: 20px;
	width: 100%;
}

.colorPickCont {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: flex-start;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	padding: 24px 0px;
	min-width: 700px;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	gap: 36px;
}
.colorPickCont .templateItem {
	cursor: pointer;
	margin: 3px;
}
.colorPickCont .templateItem.highlight {
	border: 3px solid var(--turqMain);
	margin: 0px;
}
.libraryCont h4 {
	margin: 0px 0px 12px 0px;
}
.libraryCont .libraryHeader {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	padding: 0px 24px;
	color: var(--apps-600-main);
	margin-bottom: -28px;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	font-size: 1.2em;
	gap: 60px;
}
.libraryCont .libraryHeader svg {
	fill: var(--apps-600-main);
	height: 24px;
	width: 24px;
}
.libraryCont .libraryHeader.small {
	font-size: 1em;
}
.libraryCont .libraryRow {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	border-radius: var(--borderRadiusCard);
	background-color: var(--gray-300);
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: flex-start;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	padding: 40px 24px;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	gap: 36px;
}
.libraryCont .libraryRow .rowItems {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	width: 70%;
	gap: 36px;
}
.libraryCont .libraryRow .rowCol {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: flex-start;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	color: var(--apps-900);
	min-height: 200px;
	max-width: 200px;
	width: 30%;
}
.libraryCont h3 {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	gap: 12px;
}
.libraryCont .tempDesc {
	color: var(--apps-900);
	font-size: 0.75em;
}
.libraryCont .headerDesc {
	font-size: 0.83em;
	color: var(--black);
}
.libraryCont .headerDesc a {
	text-decoration: underline;
	color: var(--black);
}
.libraryCont .libraryIcon {
	fill: var(--black);
	height: 32px;
	width: 32px;
}

.templateItem {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	position: relative;
	-webkit-box-shadow: 2px 2px 12px 2px var(--boxShadowDark);
	box-shadow: 2px 2px 12px 2px var(--boxShadowDark);
	background-color: var(--white);
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	border-radius: 8px;
	height: 200px;
	width: 200px;
	gap: 14px;
}
.templateItem input {
	position: absolute;
	margin-top: 30px;
	z-index: 10;
	top: 10px;
}
.templateItem .absoluteInput {
	width: 100%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	height: 100%;
}
.templateItem .absoluteInput textarea {
	position: absolute;
	max-height: 100px;
	max-width: 200px;
	z-index: 10;
}
.templateItem .templateName {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	margin: 30px 0px 0px 0px;
	padding: 0px 8px;
	font-size: 1.125em;
	text-align: center;
}
.templateItem .templateDescription {
	position: absolute;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	font-size: 0.875em;
	max-height: 80px;
	padding: 0px 8px;
	overflow: hidden;
	z-index: 2;
	top: 80px;
}
.templateItem .backImg {
	position: absolute;
	-webkit-mask-image: url('../assets/svg/docket/templates_40x40/iconS-tooth-crown.svg');
	mask-image: url('../assets/svg/docket/templates_40x40/iconS-tooth-crown.svg');
	background-color: var(--gray-200);
	-webkit-transform: translate(-50%, 0%);
	-ms-transform: translate(-50%, 0%);
	transform: translate(-50%, 0%);
	-webkit-mask-repeat: no-repeat;
	mask-repeat: no-repeat;
	-webkit-mask-position: center;
	mask-position: center;
	opacity: 0.4;
	padding: 0px 8px;
	height: 80px;
	width: 80px;
	z-index: 1;
	top: 70px;
	left: 50%;
}
.templateItem .backImg.crown {
	-webkit-mask-image: url('../assets/svg/docket/templates_40x40/iconS-tooth-crown.svg');
	mask-image: url('../assets/svg/docket/templates_40x40/iconS-tooth-crown.svg');
}
.templateItem .backImg.bridge {
	-webkit-mask-image: url('../assets/svg/docket/templates_40x40/iconS-tooth-bridge.svg');
	mask-image: url('../assets/svg/docket/templates_40x40/iconS-tooth-bridge.svg');
}
.templateItem .backImg.denture {
	-webkit-mask-image: url('../assets/svg/docket/templates_40x40/iconS-teeth-open.svg');
	mask-image: url('../assets/svg/docket/templates_40x40/iconS-teeth-open.svg');
}
.templateItem .backImg.implant {
	-webkit-mask-image: url('../assets/svg/docket/templates_40x40/iconS-tooth-implant.svg');
	mask-image: url('../assets/svg/docket/templates_40x40/iconS-tooth-implant.svg');
}
.templateItem .backImg.implantBridge {
	-webkit-mask-image: url('../assets/svg/docket/templates_40x40/iconS-tooth-bridge-implant.svg');
	mask-image: url('../assets/svg/docket/templates_40x40/iconS-tooth-bridge-implant.svg');
}
.templateItem .backImg.cantilBridge {
	-webkit-mask-image: url('../assets/svg/docket/templates_40x40/iconS-tooth-bridge-cantilever.svg');
	mask-image: url('../assets/svg/docket/templates_40x40/iconS-tooth-bridge-cantilever.svg');
}
.templateItem .backImg.marylBridge {
	-webkit-mask-image: url('../assets/svg/docket/templates_40x40/iconS-tooth-bridge-maryland.svg');
	mask-image: url('../assets/svg/docket/templates_40x40/iconS-tooth-bridge-maryland.svg');
}
.templateItem .addIcon {
	fill: var(--gray);
	height: 100px;
	width: 100px;
	margin: auto;
}
.templateItem .circleIcon {
	position: absolute;
	height: 16px;
	width: 16px;
	left: 8px;
	top: 8px;
}
.templateItem .circleIcon.green {
	fill: var(--successGreen);
}
.templateItem .circleIcon.red {
	fill: var(--warningRed);
}
.templateItem .dotsIcon {
	position: absolute;
	cursor: pointer;
	height: 24px;
	width: 24px;
	right: 8px;
	top: 4px;
}
.templateItem .dotsIcon:hover {
	-webkit-box-shadow: 0px 0px 6px var(--boxShadowDarkBackgroundHover);
	box-shadow: 0px 0px 6px var(--boxShadowDarkBackgroundHover);
	border-radius: 50%;
}
.templateItem .templateFooter {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	border-radius: 0px 0px 8px 8px;
	background-color: var(--gray);
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	font-size: 0.875em;
	margin-top: auto;
	min-height: 24px;
	height: 24px;
	width: 100%;
}
.templateItem .templateFooter.yellow,
.templateItem.yellow {
	background-color: var(--docketYellow);
	color: var(--white);
}
.templateItem .templateFooter.green,
.templateItem.green {
	background-color: var(--docketGreen);
	color: var(--white);
}
.templateItem .templateFooter.purple,
.templateItem.purple {
	background-color: var(--docketPurple);
	color: var(--white);
}
.templateItem .templateFooter.blue,
.templateItem.blue {
	background-color: var(--docketBlue);
	color: var(--white);
}
.templateItem .templateFooter.red,
.templateItem.red {
	background-color: var(--docketRed);
	color: var(--white);
}
.templateItem .templateFooter p {
	color: var(--white);
	font-weight: 700;
	margin: 0px;
}
.templateItem .dropdown {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	position: absolute;
	-webkit-box-shadow: 0px 0px 6px var(--boxShadowDark);
	box-shadow: 0px 0px 6px var(--boxShadowDark);
	background-color: var(--white);
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	border-radius: 16px 0px 16px 16px;
	min-width: 140px;
	max-width: 100vw;
	width: 250px;
	padding: 6px 12px;
	z-index: 99;
	right: 100%;
	top: 100%;
}
.templateItem .dropdown .dropdownItem {
	font-size: 1em;
	height: 30px;
	margin: 0px;
}
.templateItem .dropdown .dropdownItem:hover {
	color: var(--turqMain);
}

/** Kanban */
.kanbanCont {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	padding: 32px 0px;
	height: 100%;
	width: 100%;
	gap: 8px;
}
.kanbanCont .kanbanIcon {
	position: absolute;
	height: 32px;
	width: 32px;
	left: 10px;
}
.kanbanCont .row {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	min-height: 24px;
}
.kanbanCont .col {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	background-color: var(--gray-300);
	-webkit-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
	-webkit-box-shadow: var(--shadow-md);
	box-shadow: var(--shadow-md);
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	border-radius: 16px;
	overflow: hidden;
	max-width: 380px;
	height: 100%;
}
.kanbanCont .col.closed {
	overflow: hidden;
	max-width: 52px;
	min-width: 52px;
}
.kanbanCont .col .handle {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	background-color: var(--gray-200);
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	cursor: pointer;
	height: 24px;
}
.kanbanCont .col .handle svg {
	fill: var(--gray-500);
	height: 24px;
	width: 24px;
}
.kanbanCont .col .header {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	color: var(--white);
	padding: 10px;
	min-height: 52px;
}
.kanbanCont .col .header .text {
	-webkit-transition: opacity 0.4s ease-in-out;
	-o-transition: opacity 0.4s ease-in-out;
	transition: opacity 0.4s ease-in-out;
	text-wrap: nowrap;
	overflow: hidden;
	max-width: 100%;
	opacity: 1;
}
.kanbanCont .col .header .text.closed {
	text-wrap: nowrap;
	overflow: hidden;
	max-width: 0px;
	opacity: 0;
}
.kanbanCont .channel {
	background-color: #d0bb72;
	color: var(--white);
	border-radius: 8px;
	padding: 6px 16px;
}
.kanbanCont .blackHeader {
	font-size: 1em;
}
.kanbanCont .grayHeader {
	color: var(--gray-500);
	font-size: 1em;
}
.kanbanCont .dateText {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	color: var(--gray-500);
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	font-size: 1em;
	gap: 8px;
}
.kanbanCont .smallHeader {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	font-variation-settings: 'wght' 550;
	color: var(--gray-700);
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	font-size: 0.875em;
	gap: 10px;
}
.kanbanCont .priority {
	color: var(--white);
	font-variation-settings: 'wght' 550;
	background-color: var(--gray-400);
	border-radius: 16px;
	font-size: 0.875em;
	padding: 2px 8px;
}
.kanbanCont .avatar {
	border-radius: 50%;
	height: 32px;
	width: 32px;
}
.kanbanCont .list {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
	background-color: var(--gray-300);
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-ms-overflow-style: none;
	scrollbar-width: none;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	max-height: 896px;
	max-width: 100%;
	overflow: auto;
	padding: 16px;
	height: 896px;
	width: 380px;
	opacity: 1;
	gap: 16px;
}
.kanbanCont .list::-webkit-scrollbar {
	display: none;
}
.kanbanCont .list.closed {
	opacity: 0;
}
.kanbanCont .list .item {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	background-color: var(--white);
	-webkit-box-shadow: var(--shadow-xl);
	box-shadow: var(--shadow-xl);
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	border-radius: 16px;
	min-height: 204px;
	cursor: pointer;
	max-width: 100%;
	padding: 20px;
	width: 348px;
	gap: 8px;
}
.kanbanCont .list .item .row {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}
.kanbanCont .list .item .dateIcon {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	height: 24px;
	width: 24px;
}
.kanbanCont .col .btnCont {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	padding-bottom: 12px;
	margin: 0px 16px;
	max-width: 100%;
}
.kanbanCont .col .btnCont .lds-dual-ring,
.kanbanCont .col .btnCont .lds-dual-ring:after {
	height: 42px;
	width: 42px;
	margin: 0px;
	padding: 0px;
}
.kanbanCont .col .btnCont .lds-dual-ring:after {
	border-color: var(--gray-500) transparent var(--gray-500) transparent;
	height: 30px;
	width: 30px;
}

/** Invoice Details  */
.invoiceDetails {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
	width: 100%;
}
.invoiceDetails .statusRadios {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}
.invoiceItemList {
	overflow-x: auto;
}
.invoiceItemList td,
.invoiceItemList th {
	text-align: left;
	padding: 12px;
}
.invoiceItemList .lineItem .btnTd {
	width: 100px;
}

.customHoldReasonList {
	display: flex;
	gap: 3px;
}
.customHoldReasonList .customHoldReason {
	display: flex;
	justify-content: space-between;
	align-items: center;
	-webkit-box-shadow: 0px 0px 4px var(--boxShadowDarkest);
	box-shadow: 0px 0px 4px var(--boxShadowDarkest);
	background-color: var(--white);
	font-size: 18px;
	border-radius: 6px;
	padding: 6px 12px;
	gap: 12px;
}
.customHoldReasonList .customHoldReason .trashIcon {
	cursor: pointer;
	fill: var(--gray-600);
	height: 20px;
	width: 20px;
}

@media only screen and (max-width: 1600px) {
	.filesList {
		-ms-grid-columns: 1fr 46px 1fr;
		grid-template-columns: 1fr 1fr;
	}
}
@media only screen and (max-width: 1278px) {
	.filesList {
		-ms-grid-columns: 1fr;
		grid-template-columns: 1fr;
	}
	.docketBuildCont .fixedToolsCont {
		max-width: none;
		width: 310px;
	}
}
@media only screen and (max-width: 1024px) {
	.teamProfileCont .topRow,
	.teamProfileCont .midRow,
	.teamProfileCont .botRow {
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}
	.teamProfileCont .thinCard,
	.teamProfileCont .thickCard {
		width: 100% !important;
	}
	.docketBuildCont {
		padding: 12px;
	}
	.docketBuildCont .col .fieldPre .fieldTools {
		top: 10px;
	}
	.docketBuildCont .col.docket {
		margin: 0px 20px 0px 0px;
	}
	.btn2-medium {
		min-width: 140px;
		width: 140px;
	}
	.docketBuildCont .fixedToolsCont {
		min-width: 160px;
		width: 160px;
	}
	.libraryCont {
		margin: 0px 10px;
	}
	.libraryCont .libraryRow {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
	}
	.libraryCont .libraryRow .rowItems {
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
	}
	.libraryCont .libraryRow .rowCol {
		width: 100%;
	}
	.kanbanCont {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		gap: 16px;
	}
	.kanbanCont .col {
		max-height: 1050px;
		max-width: 380px;
		height: 1050px;
	}
	.kanbanCont .col.closed {
		max-width: 380px;
		min-width: 380px;
		max-height: 100px;
		min-height: 100px;
	}
	.kanbanCont .list.closed {
		max-width: 380px;
		min-width: 380px;
		max-height: 100px;
		min-height: 100px;
	}
	.kanbanCont .list .item {
		-webkit-box-align: start;
		-ms-flex-align: start;
		align-items: flex-start;
	}
	.kanbanCont .col .list .item .row {
		width: 100%;
	}
	.kanbanCont .col .header .text.closed {
		max-width: 100%;
		opacity: 1;
	}
	.kanbanCont .col .handle {
		min-height: 50px;
	}
	.filesList .fileCont {
		max-width: 100%;
	}
}

@media only screen and (max-width: 768px) {
	.packagesCont {
		padding: 20px 0px;
	}

	.purchaseOrdersList .orderItem .orderData,
	.purchaseOrdersList .colRow .rowItem {
		font-size: 10px;
	}

	.billIcon {
		height: 16px;
		width: 16px;
	}
	.invitationsListContainer .invitationsList .invite {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
	}
	.invitationsListContainer .colHeaders {
		display: none;
	}
	.invitationsListContainer .invitationsList .btnCont {
		-webkit-box-pack: start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-ms-flex-direction: row;
		flex-direction: row;
		padding-left: 6px;
		width: 90%;
	}
	.invitationsListContainer .invitationsList .invite h3 {
		padding-left: 6px;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		max-width: 100%;
		width: 100%;
	}
	.addressManagementContainer .addressItem .leftCol {
		overflow-x: hidden;
		overflow-y: auto;
		min-width: 100px;
		padding: 12px;
	}
	.addressManagementContainer .addressItem .addressButtons {
		min-width: 100px;
		padding: 20px 12px;
	}
	.addressManagementContainer .addressItem .addressButtons button {
		width: clamp(80px, 80px, 80px);
		min-width: 80px;
		margin: 0px;
	}

	.teamHeader {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: flex-start;
		padding: 24px 0px;
		gap: 24px;
	}
	.kanbanCont .col {
		max-width: 280px;
	}
	.kanbanCont .col.closed {
		max-width: 280px;
		min-width: 280px;
		max-height: 100px;
		min-height: 100px;
	}
	.kanbanCont .list.closed {
		max-width: 280px;
		min-width: 280px;
		max-height: 100px;
		min-height: 100px;
	}
	.packageCont {
		-ms-flex-item-align: center;
		-ms-grid-row-align: center;
		align-self: center;
		max-width: 95%;
	}
	.dispatchCont {
		padding: 12px;
	}
}
@media only screen and (max-width: 648px) {
	.filesList {
		padding: 24px 12px;
	}
	.filesList .fileCont {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		max-width: 320px;
		max-height: none;
		margin: auto;
		width: 100%;
	}
	.filesList .fileCont .col {
		-webkit-box-orient: vertical;
		-webkit-box-direction: reverse;
		-ms-flex-direction: column-reverse;
		flex-direction: column-reverse;
		max-width: 200px;
	}
	.filesList .fileCont .textCont {
		padding: 20px;
	}
	.memberManagementContainer {
		padding: 20px 12px;
		border-radius: 0;
	}
	.libraryCont .libraryHeader {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
	}
	.permissionsCont {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.teamFiles .paginationContainer {
		padding: 6px;
	}
	.appDetails {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		padding: 12px;
	}
	.appDetails .thinCol {
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		width: 100%;
		max-width: 100%;
	}

	.appTile .row {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.section {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
	}
	.addressManagementContainer {
		padding: 12px;
	}
	.addressesContainer .formRowTop {
		padding: 0px;
		margin: 0px;
	}
	.addressesContainer span {
		text-align: center;
		min-width: 80px;
	}
	.invoiceItemsSection .topBar .header {
		font-size: 0.75em;
		text-wrap: wrap;
		padding: 3px;
	}
}
@media only screen and (max-width: 465px) {
	.packageCont {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		-ms-flex-item-align: center;
		-ms-grid-row-align: center;
		align-self: center;
		padding: 12px;
		width: 90%;
		gap: 12px;
	}
	.packagesCols {
		display: none;
	}
	.packagesCont .btnCont {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		max-width: 140px;
		gap: 12px;
	}
	.packagesCont p {
		border-bottom: 1px solid var(--gray-500);
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		width: 100%;
	}
	.dispatchCont .wideModal {
		padding: 12px;
	}

	.appsOverview {
		padding: 12px;
	}

	.teamHeader .btnCont {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		height: 100%;
	}

	.teamAppsCont .appsHeader {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		padding: 24px 12px 12px 12px;
		margin: 0px;
		gap: 12px;
	}
	.teamAppsCont .appsHeader p {
		text-align: center;
	}
	.teamAppsCont .appsRow {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		padding: 24px 12px;
	}
	.teamAppsCont .appsRow .leftDesc {
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		max-width: 100%;
		width: 100%;
	}
	.teamAppsCont .appsRow .rowItems {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
	}
}
