form {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	max-width: 90vw;
	margin: 0px 10px;
	height: 100%;
	width: 100%;
}

p.extraBold {
	margin: 0px;
}

.formTextarea {
	outline: none;
	border: 1px solid var(--disabled);
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	padding: 11px 10px;
	width: 100%;
	height: 200px;
	min-width: 100px;
	min-height: 100px;
	max-width: 480px;
	max-height: 100%;
	border-radius: 3px;
	margin: 5px 0px;
	font-size: 1em;
}
.absoluteLoading {
	position: absolute;
	bottom: 12px;
	right: 6px;
}
.absoluteLoading .lds-dual-ring {
	height: 35px;
	width: 35px;
	margin: 0px;
}
.absoluteLoading .lds-dual-ring:after {
	height: 20px;
	width: 20px;
	margin: 0px;
}
.formTextarea.short {
	min-height: 60px;
	height: 100px;
}

.formTextarea:focus {
	border: 2px solid var(--turqMain);
	padding: 10px;
}

.inputContainer {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	position: relative;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	max-width: 480px;
	width: 100%;
	margin: 0;
}

.inputContainer .inputIcon {
	position: absolute;
	right: 10px;
	width: 22px;
	height: 22px;
	fill: var(--gray);
}

.splitInputContainer {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	position: relative;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	max-width: 480px;
	width: 100%;
	margin: 0;
}
.splitInputContainer .textFieldInput {
	border-radius: 0px 3px 3px 0px;
	z-index: 2;
}
.splitInputContainer .textFieldInput:disabled {
	color: var(--gray-500);
}
.splitInputContainer .textFieldReadOnly {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	border: 1px solid var(--gray-200);
	background-color: var(--white);
	border-radius: 3px 0px 0px 3px;
	text-wrap: nowrap;
	color: var(--gray-600);
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	position: relative;
	padding: 0px 12px;
	margin: 5px 0px;
	font-size: 1em;
	height: 38px;
}

.splitInputContainer.number {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	position: relative;
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	max-width: 480px;
	width: 100%;
	margin: 0;
}
.splitInputContainer.number .textFieldInput {
	border-radius: 8px 0px 0px 8px;
	text-align: center;
	width: 60px;
}
.splitInputContainer.number .textFieldReadOnly {
	background-color: var(--smoke-gray-50);
	border-radius: 0px 8px 8px 0px;
	font-weight: 700;
	font-size: 0.875em;
}

.searchContainer {
	display: inline-block;
	position: relative;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	max-width: 480px;
	width: 100%;
	margin: 0;
}

.searchContainer .inputIcon {
	position: absolute;
	fill: var(--gray);
	height: 22px;
	right: 10px;
	width: 22px;
	top: 12px;
}

.fieldTextContainer {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	font-weight: 700;
	max-width: 480px;
	width: 100%;
}

.fieldTextContainer.radio {
	-webkit-box-pack: end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	text-align: end;
	margin-top: 5px;
	min-width: 200px;
	max-width: 200px;
}

.textFieldInput {
	position: relative;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	border: 1px solid var(--disabled);
	padding: 0px 12px;
	color: var(--black);
	border-radius: 3px;
	margin: 5px 0px;
	font-size: 1em;
	height: 38px;
	width: 100%;
}
.textFieldInput::-webkit-input-placeholder {
	color: var(--gray-30);
}
.textFieldInput::-moz-placeholder {
	color: var(--gray-30);
}
.textFieldInput:-ms-input-placeholder {
	color: var(--gray-30);
}
.textFieldInput::-ms-input-placeholder {
	color: var(--gray-30);
}
.textFieldInput::placeholder {
	color: var(--gray-30);
}

.textFieldInput + .inputIcon {
	display: none;
}

.textFieldInput:focus {
	border: 2px solid var(--turqMain);
	padding: 0px 11px;
}

.textFieldInput .error {
	border: 2px solid var(--warningRed);
	padding: 0px 11px;
}

.textFieldInput .success {
	border: 2px solid var(--successGreen);
	padding: 0px 11px;
}

.inputIconSuccess {
	fill: var(--successGreen);
	position: absolute;
	right: 10px;
	width: 22px;
	height: 22px;
}

.inputIconError {
	fill: var(--warningRed);
	position: absolute;
	right: 10px;
	width: 22px;
	height: 22px;
}

.submitBtn {
	height: 40px;
	min-width: 200px;
	border-radius: 25px;
	cursor: pointer;
	border: none;
	color: var(--white);
	background-color: var(--turqMain);
	outline: none;
	font-size: 0.94em;
}

.submitBtn:hover {
	background-color: var(--turqDarker);
	-webkit-box-shadow: 1px 0px 6px 1px var(--black);
	box-shadow: 1px 0px 6px 1px var(--black);
}

.submitBtnSecondary {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: space-evenly;
	-ms-flex-pack: space-evenly;
	justify-content: space-evenly;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	cursor: pointer;
	width: 200px;
	height: 40px;
	border: 2px solid var(--turqDark);
	border-radius: 25px;
	padding: 0px 10px;
	color: var(--turqDark);
	font-size: 0.94em;
}

.submitBtnSecondary svg {
	padding: 0px 10px 0px 10px;
	fill: var(--turqDark);
}

.submitBtnSecondary:hover {
	-webkit-box-shadow: 1px 0px 6px 1px var(--black);
	box-shadow: 1px 0px 6px 1px var(--black);
	background-color: var(--veryLightGray);
}

.loginForm {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
}

.loginInput {
	background-color: var(--lightGray);
	margin: 10px 0px;
	width: 300px;
	padding: 0px 10px;
	font-size: 0.875em;
}

.optionsIcon {
	height: 22px;
	width: 22px;
	fill: var(--gray);
}
.optionsIconCont {
	position: absolute;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	right: 6px;
	top: 9px;
	padding: 3px;
	border-radius: 50%;
	cursor: pointer;
	fill: var(--gray);
}
.optionsIconCont:focus .optionsIcon {
	fill: var(--turqDark);
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Order Filter */
.orderFilter {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	padding: 0px 60px 100px 60px;
}
.orderFilter .checkboxLabel {
	-ms-flex-item-align: start;
	align-self: flex-start;
}
.orderFilter h3 {
	margin: 20px 0px;
}
.orderFilter .formRowCenter {
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	max-width: 100vw;
	min-height: 0;
	margin: 0px;
}
.orderFilter .selectCustom,
.orderFilter .formChoices {
	margin: 0px;
}
.orderFilter .textFieldInput {
	margin: 0px 0px 10px 0px;
}

/* Modals */
.teamChooseModal::-webkit-scrollbar,
.wideModal::-webkit-scrollbar,
.flexModal::-webkit-scrollbar,
.fixedModal::-webkit-scrollbar,
.mainModal::-webkit-scrollbar {
	display: none;
}
.mainModal {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	position: absolute;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-shadow: 1px 0px 6px 1px var(--gray);
	box-shadow: 1px 0px 6px 1px var(--gray);
	border-radius: var(--borderRadiusReg);
	background-color: var(--white);
	-webkit-transform: translate(-50%, 0%);
	-ms-transform: translate(-50%, 0%);
	transform: translate(-50%, 0%);
	padding: 24px;
	overflow: auto;
	max-width: 90%;
	width: 600px;
	z-index: 99;
	left: 50%;
	top: 0px;
	margin-bottom: 24px;
}

.mainModal .extraBold {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	min-height: 25px;
	min-width: 200px;
	max-width: 200px;
	font-weight: 600;
	text-align: right;
}

.fixedModal {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	position: fixed;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	width: 480px;
	max-width: min(352px, 90vw);
	max-height: 300px;
	left: 50%;
	top: 50%;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	overflow: auto;
	padding: 24px;
	z-index: 99;
	background-color: var(--white);
	-webkit-box-shadow: 1px 0px 6px 1px var(--gray);
	box-shadow: 1px 0px 6px 1px var(--gray);
	border-radius: var(--borderRadiusReg);
}

.fixedModal h3 {
	margin: 0px 0px 20px 0px;
	text-align: center;
}

.fixedModal .btnCont {
	margin: 32px 0px 0px 0px;
	width: 100%;
	-webkit-box-flex: 1;
	-ms-flex: auto;
	flex: auto;
}
.fixedModal .btnCont button {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	width: 100%;
	margin: 0px;
	-webkit-box-flex: 1;
	-ms-flex: 1;
	flex: 1;
}

.fixedModal .btnCont .functionalButtonSecondary {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	margin: 10px 0px;
}

.flexModal {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	position: absolute;
	left: 50%;
	top: 100px;
	-webkit-transform: translate(-50%, 0%);
	-ms-transform: translate(-50%, 0%);
	transform: translate(-50%, 0%);
	padding: 48px 24px 24px 24px;
	min-width: min(600px, 90vw);
	max-width: min(90vw, 90%);
	box-sizing: border-box;
	z-index: 99;
	background-color: var(--white);
	-webkit-box-shadow: 1px 0px 6px 1px var(--gray);
	box-shadow: 1px 0px 6px 1px var(--gray);
	border-radius: var(--borderRadiusReg);
	-webkit-animation: fadeIn 0.1s;
	animation: fadeIn 0.1s;
}
.flexModal form {
	margin: 0px;
}
.flexModal .mainFormCont {
	height: 100%;
}
.flexModal h2,
.flexModal h4 {
	display: block;
	text-align: left;
	max-width: 500px;
	margin: 20px;
}
.flexModal .btnCont {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	max-width: 100%;
	gap: 12px;
}
.flexModal .closeIcon {
	position: absolute;
	height: 28px;
	width: 28px;
	right: 12px;
	top: 12px;
}

.uploadModal {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	position: fixed;
	left: 50%;
	top: 35%;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	min-height: 106px;
	max-width: 90vw;
	min-height: 106px;
	padding: 36px;
	width: 440px;
	z-index: 99;
	background-color: var(--white);
	border-radius: var(--borderRadiusReg);
	-webkit-animation: fadeIn 0.1s;
	animation: fadeIn 0.1s;
}
.upload label {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	font-size: 0.875em;
	height: 100%;
	width: 100%;
	gap: 24px;
}
.upload .cloudIcon {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	min-height: 24px;
	height: 24px;
	width: 24px;
	padding: 8px;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	border-radius: 12px;
	border: 6px solid var(--primary-50, #e4f7f9);
	background: var(--primary-100, #c2ecf0);
}
.upload .closeIcon {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	min-height: 20px;
	height: 20px;
	width: 20px;
	padding: 8px;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	border-radius: 12px;
}
.upload .highlight {
	color: var(--primary-600);
	font-size: 1.14em;
	font-weight: 700;
}
.upload .chosenFileList {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	position: fixed;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	border-radius: var(--borderRadiusReg);
	max-height: 37.5vh;
	max-width: 90vw;
	width: 440px;
	top: 105%;
	gap: 18px;
}
.upload .chosenFileList button {
	min-height: 36px;
}
.upload .chosenFileList .listCont {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	overflow-y: auto;
	padding-right: 6px;
	margin-right: -6px;
	gap: 6px;
}
.upload .chosenFileList .listedFile {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	border-radius: var(--borderRadiusReg);
	background-color: var(--white);
	box-sizing: border-box;
	padding: 16px;
	width: 100%;
	gap: 12px;
}
.upload .chosenFileList .listedFile .col {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: flex-start;
	-ms-flex-align: flex-start;
	align-items: flex-start;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	flex-direction: column;
	border-radius: var(--borderRadiusReg);
	background-color: var(--white);
	width: 100%;
}
.upload .chosenFileList .listedFile .col h4 {
	font-variation-settings: 'wght' 500;
	color: var(--smoke-gray-700);
	word-wrap: break-word;
	font-size: 0.875em;
	max-width: 50vw;
}
.upload .chosenFileList .listedFile .col p {
	margin: 0px 0px 6px 0px;
}
.upload .chosenFileList .listedFile .col .row {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	width: 100%;
}
.upload .chosenFileList .listedFile .col .checkIcon {
	background-color: var(--primary-600);
	fill: var(--white);
	border-radius: 50%;
	padding: 2px;
	height: 16px;
	width: 16px;
}
.uploadInputCont label {
	align-items: flex-start;
}

.dragActive {
	border: 3px dotted var(--primary-600);
	border-radius: 12px;
}
.dragFileElement {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	height: 100%;
	width: 100%;
	gap: 18px;
}
.dragFileElement .closeIcon {
	position: absolute;
	right: -24px;
	top: -24px;
}

.wideModal {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	position: fixed;
	-webkit-box-shadow: 1px 0px 6px 1px var(--gray);
	box-shadow: 1px 0px 6px 1px var(--gray);
	border-radius: var(--borderRadiusReg);
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	background-color: var(--white);
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	overflow-x: hidden;
	overflow-y: auto;
	max-width: 95%;
	max-height: 95%;
	padding: 24px;
	width: 640px;
	z-index: 99;
	left: 50%;
	top: 50%;
}
.wideModal .closeIcon {
	position: absolute;
	z-index: 10;
	height: 28px;
	width: 28px;
	right: 12px;
	top: 12px;
}

.wideModal .mainFormCont {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	height: 100%;
}

.wideModal .modalRow {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	width: 100%;
	padding: 40px 20px;
}

.wideModal .btnCont {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	padding: 20px;
}

.wideModal .modalRowCenter {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	width: -moz-fit-content;
	width: -webkit-fit-content;
	width: fit-content;
	padding: 20px;
}

.wideModal .modalColumn {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

.mainModal .addressButtons {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	margin: 25px 0px;
}

.mainModal .lds-dual-ring {
	height: 100px;
	width: 100px;
	margin: 20px 0 0 0;
}

.fadeInModal {
	--left: 40px;
	--bottom: 40px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	position: fixed;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	bottom: var(--bottom);
	left: var(--left);
	background-color: var(--white);
	-webkit-box-shadow: 0px 0px 6px var(--black);
	box-shadow: 0px 0px 6px var(--black);
	padding: 10px 20px;
	border-radius: 5px;
	font-size: 1.125em;
	width: 300px;
	min-height: 40px;
	z-index: 200;
	-webkit-animation: fadeInModal 0.5s ease;
	animation: fadeInModal 0.5s ease;
}

.fadeOutModal {
	--left: -340px;
	--bottom: 40px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	position: fixed;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	bottom: var(--bottom);
	left: var(--left);
	background-color: var(--white);
	-webkit-box-shadow: 0px 0px 6px var(--black);
	box-shadow: 0px 0px 6px var(--black);
	padding: 10px 20px;
	border-radius: 5px;
	font-size: 1.125em;
	width: 300px;
	min-height: 40px;
	z-index: 200;
	opacity: 0;
	-webkit-animation: fadeOutModal 0.5s ease;
	animation: fadeOutModal 0.5s ease;
}

.teamChooseModal {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	position: absolute;
	border-radius: var(--borderRadiusCard);
	-webkit-transform: translate(-50%, 0%);
	-ms-transform: translate(-50%, 0%);
	transform: translate(-50%, 0%);
	-webkit-box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03);
	box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03);
	-webkit-animation: fadeIn 0.1s;
	background-color: var(--white);
	padding: 30px 70px 70px 70px;
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	animation: fadeIn 0.1s;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	overflow-y: auto;
	max-width: 90%;
	width: 1284px;
	z-index: 99;
	left: 50%;
	top: 50px;
}
.teamChooseModal h2 {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	margin: 0px auto 80px 0px;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	color: var(--team);
	width: 100%;
}
.teamChooseModal .headerIcon {
	margin-right: 15px;
	fill: var(--team);
	height: 24px;
	width: 24px;
}
.teamChooseModal .choices {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	gap: 36px;
	max-width: 1540px;
}
.teamChooseModal h4 {
	color: var(--gray-700);
	text-align: center;
	line-height: 20px;
}
.teamChooseModal .choices .teamChoice {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-shadow: var(--shadow-xl);
	box-shadow: var(--shadow-xl);
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	padding: 10px 20px 20px 20px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	border-radius: 8px;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	height: 200px;
	width: 200px;
}
.teamChooseModal .choices .teamChoice.highlight {
	border: 2px solid var(--primary-600);
}
.teamChooseModal .choiceIcon {
	background-color: var(--white);
	fill: var(--gray);
	max-width: 160px;
	height: 60px;
}
.teamChooseModal .teamImg {
	max-width: 160px;
	max-height: 60px;
}

/* Multi Step Form */
.multiStepForm {
	background-color: var(--gray-300);
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	border-radius: 24px;
	padding: 36px 70px;
	max-width: 1540px;
	height: 100%;
	width: 100%;
}
.multiStepForm .headerCol {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	margin: 30px 24px;
}
.multiStepForm .mainHeader {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	color: var(--primary-700);
	font-size: 1.5em;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	gap: 20px;
}
.multiStepForm .subHeader {
	color: var(--black);
	font-size: 1.125em;
	margin-left: 48px;
	font-weight: 400;
}
.multiStepForm .formIcon {
	fill: var(--primary-700);
	height: 28px;
	width: 28px;
}
.multiStepForm .sliderMask {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	background-color: var(--gray-50);
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: flex-start;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	border-radius: 20px;
	padding: 0px 72px;
	-ms-flex-item-align: center;
	-ms-grid-row-align: center;
	align-self: center;
	overflow-x: hidden;
	overflow-y: visible;
	max-width: 100%;
	width: 100%;
}
.multiStepForm .sliderMask::-webkit-scrollbar {
	display: none;
}

.multiStepForm .sliderMask:after {
	content: '';
	position: absolute;
	top: 0px;
	left: 0;
	height: 100%;
	width: 72px;
	z-index: 3;
	background: var(--gray-50);
}
.multiStepForm .sliderMask:before {
	content: '';
	position: absolute;
	top: 0px;
	right: 0;
	height: 100%;
	width: 72px;
	z-index: 3;
	background: var(--gray-50);
}
.multiStepForm form {
	margin: 0px;
}
.multiStepForm .fileCont {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	border: 1px solid var(--gray-300);
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	background: var(--white);
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	border-radius: 12px;
	max-width: 100%;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	padding: 16px;
	width: 348px;
	gap: 12px;
}
.multiStepForm .fileCont .fileRow {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	width: 100%;
}
.multiStepForm .fileCont .placeholder {
	fill: var(--gray-300);
	min-height: 44px;
	min-width: 44px;
	height: 44px;
	width: 44px;
}
.multiStepForm .fileCont .text {
	font-size: 0.875em;
	text-align: left;
	margin: 0px;
}
.multiStepForm .fileCont .trashIcon {
	fill: var(--gray-500);
	border-radius: 50%;
	cursor: pointer;
	padding: 6px;
	height: 20px;
	width: 20px;
	margin: -6px -6px 0px 0px;
}
.multiStepForm .fileCont .trashIcon:hover {
	-webkit-box-shadow: 1px 1px 6px var(--boxShadowDarkBackgroundHover);
	box-shadow: 1px 1px 6px var(--boxShadowDarkBackgroundHover);
}
.multiStepForm .innerCont {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: flex-start;
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	z-index: 2;
	max-width: 100%;
}
.multiStepForm .formStep {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	padding: 40px 0px 0px 0px;
	max-width: 100%;
}
.multiStepForm .formStep.closed {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	overflow: hidden;
	max-width: 100%;
	max-height: 0px;
	padding: 0px;
}
.multiStepForm .formStep.alignCenter {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.multiStepForm .btnContCol {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	padding: 0px 12px;
	max-width: 300px;
	width: 100%;
	gap: 12px;
}
.multiStepForm .sliderMask .btnCont {
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	padding: 16px 12px 40px 12px;
	margin: auto 0px 0px 0px;
	width: 100%;
}
.multiStepForm .innerCol .btnCont {
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	padding: 12px 0px 0px 0px;
	width: 100%;
}
.multiStepForm .searchCont {
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	margin: 8px 0px 0px 0px;
	width: 100%;
}
.multiStepForm .btnCont .primaryBtns {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	margin-left: auto;
	gap: 12px;
}
.multiStepForm .thinCol {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	min-width: 40px;
}
.multiStepForm .thinCol .iconCont {
	margin: auto 0px 16px 0px;
}
.multiStepForm .thinCol .iconCont svg {
	fill: var(--gray-500);
}

.multiStepForm .avatarRow {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	gap: 8px;
}
.multiStepForm .avatarRow .teamChoicesIcon {
	cursor: pointer;
}
.multiStepForm .previewIcon {
	height: 40px;
	width: 40px;
	border-radius: 50%;
	cursor: pointer;
}
.multiStepForm .addBtn {
	margin-left: 310px;
}
.multiStepForm .companyLogo {
	height: 140px;
}
.multiStepForm .innerCont .lds-dual-ring {
	margin: 0px;
}
.multiStepForm .linkText:hover a {
	color: var(--primary-700-main);
}
.multiStepForm h3 {
	color: var(--gray-900);
}
.multiStepForm .fieldTextContainer h4 {
	color: var(--gray-900);
}
.multiStepForm .pageHeader {
	margin-left: 24px;
}
.multiStepForm .fieldDesc {
	color: var(--gray-600);
	font-size: 0.875em;
	margin-bottom: 0px;
	font-weight: 300;
}
.multiStepForm .inviteRow {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-flex: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	gap: 16px;
}
.multiStepForm .formNav {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	padding-top: 16px;
}
.multiStepForm .formNav .filler {
	width: 164px;
}
.multiStepForm .formSection {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	border-bottom: 1px solid var(--gray-300);
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: flex-start;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	margin-bottom: 24px;
}
.multiStepForm .formStep .formSection.center {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.multiStepForm .formSection .sectionRow {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	width: 100%;
	gap: 32px;
}
.multiStepForm .formSection .sectionRow .btnCont {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	margin: 0px;
}
.multiStepForm .formSection .wideCol {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	max-width: 100%;
	width: 100%;
}
.multiStepForm .formSection .checkRow {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	margin: 8px 0px;
	gap: 32px;
}
.multiStepForm .formSection .checkRow p {
	color: var(--gray-700);
	font-size: 0.875em;
	font-weight: 600;
	width: 280px;
}
.multiStepForm .formSection .innerCol {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	width: 100%;
}
.multiStepForm .formSection .innerCol .identifierText {
	margin-bottom: 12px;
	font-style: italic;
	font-weight: 600;
}
.multiStepForm .formSection .innerCol:nth-child(1) {
	max-width: 280px;
}
.multiStepForm .formSection .innerCol:nth-child(2) {
	max-width: 680px;
}

.multiStepForm .formSection .innerCol .packageChoiceList .packageChoiceItem .timestampCont {
	width: 50%;
}
.multiStepForm .formSection .innerCol .packageChoiceList .packageChoiceItem .bottomCont {
	flex-direction: row;
}

.multiStepForm .formSection .wideInnerCol {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	width: 100%;
}
.multiStepForm .btnCont.reverse {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: end;
	-ms-flex-align: end;
	align-items: flex-end;
	height: 100%;
	margin-bottom: 5px;
}

.multiStepForm .formColumnCenter,
.multiStepForm .formRowCenter {
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: flex-start;
	margin: 3px 0px;
	padding: 0px;
}
.multiStepForm .formRowCenter.center {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.multiStepForm .mainFormCont .formColumnCenter,
.multiStepForm .mainFormCont .formRowCenter {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	margin: 20px 0;
	max-width: 640px;
	min-height: 52px;
	padding: 0 20px;
	width: 100%;
}
.multiStepForm .formRowCenter .inputLabel {
	padding: 0px;
	margin: 0px;
}
.multiStepForm .formSection .innerCol .selectCustom {
	margin: 8px 0px;
}
.multiStepForm .formSection .innerCol .inputContainer {
	margin: 3px 0px;
}
.multiStepForm .formSection .innerCol .inputContainer:nth-child(1),
.multiStepForm .formSection .innerCol .inputContainer:nth-child(1) .textFieldInput {
	margin: 0px 0px 3px 0px;
}
.multiStepForm .teamPicture {
	max-width: 100%;
	width: 400px;
}
.multiStepForm .orderItemList {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	gap: 12px;
}
.multiStepForm .orderItemList .orderItem {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	border-bottom: 1px solid var(--gray-300);
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	padding: 0px 12px 12px 0px;
	gap: 12px;
}
.multiStepForm .orderItemList .orderItem:last-child {
	border-bottom: none;
}
.multiStepForm .orderItemList .orderItem h4 {
	max-width: 50%;
	width: 100%;
}

/* Custom radio */
input[type='radio'] {
	position: absolute;
	opacity: 0;
}

input[type='radio'] ~ .customRadio {
	cursor: pointer;
	min-height: 23px;
	min-width: 23px;
	border: 1px solid var(--gray);
	border-radius: 50%;
	background-color: var(--white);
}

input[type='radio']:checked ~ .customRadio {
	min-height: 11px;
	min-width: 11px;
	border: 7px solid var(--turqMain);
}

/* Custom checkbox */
input[type='checkbox'] {
	position: absolute;
	opacity: 0;
}

input[type='checkbox'] ~ .customCheckbox {
	position: relative;
	cursor: pointer;
	min-height: 25px;
	min-width: 25px;
	height: 25px;
	width: 25px;
	border-radius: 3px;
	border: 1px solid var(--gray);
	background-color: var(--white);
}

input[type='checkbox']:checked ~ .customCheckbox {
	height: 25px;
	width: 25px;
	border: 1px solid var(--turqMain);
	background-color: var(--turqMain);
}

input::-webkit-input-placeholder {
	overflow: visible;
}

input::-moz-placeholder {
	overflow: visible;
}

input:-ms-input-placeholder {
	overflow: visible;
}

input::-ms-input-placeholder {
	overflow: visible;
}

input::placeholder {
	overflow: visible;
}

.customCheckbox:after {
	content: '';
	position: absolute;
	display: none;
}

.customRadio,
.customCheckbox {
	margin: 3px 20px 0px 0px;
}

input[type='checkbox']:checked ~ .customCheckbox:after {
	display: block;
}

input[type='checkbox'] ~ .customCheckbox:after {
	top: 2px;
	left: 8px;
	width: 5px;
	height: 14px;
	border: solid var(--white);
	border-width: 0px 3px 3px 0px;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}

/* Choose File Input */
input[type='file'] {
	display: none;
	width: 100%;
	height: 100%;
}

.fileIcon {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	height: 48px;
	width: 48px;
	overflow: visible;
	position: relative;
}
.plus {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	height: 20px;
	width: 20px;
	-webkit-box-shadow: 1px 1px 6px var(--boxShadowDarkest);
	box-shadow: 1px 1px 6px var(--boxShadowDarkest);
	border-radius: 25px;
}
.plus:hover {
	-webkit-box-shadow: 1px 1px 6px var(--boxShadowDarkBackgroundHover);
	box-shadow: 1px 1px 6px var(--boxShadowDarkBackgroundHover);
}
.fileIcon label {
	cursor: pointer;
}

.fileInput {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	height: 48px;
	width: 100%;
	max-width: 480px;
	margin: 5px 0px;
	padding: 12px 0px;
	overflow: visible;
	position: relative;
	font-size: 0.94em;
}

.fileInput label {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	cursor: pointer;
	position: relative;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	font-variation-settings: var(--bold);
	border: 2px solid var(--turqDark);
	background-color: var(--white);
	color: var(--turqDark);
	fill: var(--turqDark);
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	border-radius: 25px;
	min-width: 180px;
	width: 200px;
	height: 40px;
}

.fileInput svg {
	margin: 0px 20px;
}

.fileInput.thin {
	margin: 0;
	padding: 0;
	max-height: 40px;
	min-width: 80px;
	max-width: 80px;
}

.fileInput.thin .uploadIcon {
	margin: 0;
}

.fileInput.thin label {
	margin: 0;
	padding: 0;
	max-height: 40px;
	min-width: 80px;
	max-width: 80px;
}

.fileInput.disabled label {
	display: block;
	position: relative;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	min-width: 180px;
	width: 230px;
	height: 40px;
	border-radius: 25px;
	border: 2px solid var(--mediumGray);
	background-color: var(--white);
	color: var(--gray);
	fill: var(--gray);
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	font-variation-settings: var(--bold);
	cursor: default;
}

.fileInput label:hover {
	background-color: var(--veryLightGray);
	-webkit-box-shadow: 1px 0px 6px 1px var(--black);
	box-shadow: 1px 0px 6px 1px var(--black);
}
.fileInput.disabled label:hover {
	background-color: var(--white);
	-webkit-box-shadow: none;
	box-shadow: none;
}

.fileInput span {
	margin-right: 10px;
	font-weight: 600;
	white-space: nowrap;
}

.filePreviewIcon {
	-webkit-box-shadow: 0px 0px 4px 0px var(--boxShadowDarkest);
	box-shadow: 0px 0px 4px 0px var(--boxShadowDarkest);
	background-color: var(--white);
	border-radius: 50%;
	cursor: pointer;
	padding: 2px;
	min-height: 28px;
	min-width: 28px;
	max-height: 28px;
	max-width: 28px;
	fill: var(--gray);
}

.attachment {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	width: 300px;
	margin: 10px 0px;
}

.textFieldSelect {
	margin: 5px 0px 5px 30px;
}

.textFieldSelect .item {
	width: 20px;
}

#centerFieldText {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.formColumnCenter .fieldTextLeftContainer p {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	font-weight: 700;
	width: 100%;
}

.textFieldSelect:focus {
	width: 232px;
	border: 2px solid var(--turqMain);
}

/* CUSTOM SELECT */
.selectCustom {
	position: relative;
	display: inline-block;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	border: 1px solid var(--disabled);
	background-color: var(--white);
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	color: var(--black);
	border-radius: 3px;
	user-select: none;
	font-weight: 400;
	max-width: 480px;
	margin: 5px 0px;
	cursor: pointer;
	font-size: 1em;
	padding: 0px;
	height: 40px;
	width: 100%;
}
.selectCustom p {
	white-space: nowrap;
	overflow: hidden;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	max-width: 90%;
}
.selectCustom.short {
	min-width: 80px;
	max-width: 80px;
	margin: 0px;
}
.selectCustom.warning {
	border: 2px solid var(--warningRed);
}

.selectCustom.success {
	border: 2px solid var(--successGreen);
}

.selectCustom.focus {
	border: 2px solid var(--turqMain);
}

.selectCustom:focus {
	border: 2px solid var(--turqMain);
}

.selectCustom p {
	text-align: left;
	padding: 8px 12px;
	margin: 0px;
}

.selectCustom svg {
	position: absolute;
	top: 8px;
	right: 10px;
}

.options {
	position: absolute;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	cursor: default;
	-webkit-box-shadow: 0px 4px 4px var(--boxShadowDark);
	box-shadow: 0px 4px 4px var(--boxShadowDark);
	background-color: var(--white);
	border-radius: 6px;
	margin: 5px 0px 0px 0px;
	color: var(--black);
	max-height: 205px;
	padding: 10px 0px;
	max-width: 480px;
	overflow: auto;
	z-index: 99;
	width: 100%;
}
.options.textInput {
	top: 65px;
}
.options.reverse {
	bottom: 105%;
	border-top: 1px solid var(--gray);
	border-bottom: none;
	margin: 0px 0px 5px 0px;
}

.options .option {
	padding: 10px 20px;
	text-align: start;
	cursor: pointer;
}

.options .option:hover,
.options .option.focused {
	background-color: var(--bgMarked);
	outline: none;
}

/* Main form CSS */
.mainFormCont {
	position: relative;
	margin: 20px 0px;
}
.mainFormCont.wide {
	width: 100%;
}

.mainFormCont.fixed {
	position: relative;
	margin: 20px 0px;
	width: 680px;
}

.mainFormCont .fieldDesc {
	margin: 20px 0px;
}

.mainFormCont,
.mainFormCont form {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.orderSellerLogo {
	border-radius: 5px;
}

.mainFormCont .inputLabel {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	font-weight: 700;
	text-align: right;
	font-size: 1em;
	line-height: 19px;
	margin: 0px;
}

.mainFormCont h2,
.mainFormCont .formSection h3,
.mainFormCont .formSection h4 {
	margin: 30px 0px;
}

.mainFormCont .formTitle {
	width: 80%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
}

.mainFormCont .formLightTitle {
	font-variation-settings: var(--medium);
	font-size: 1em;
	width: 80%;
}

.mainFormCont .graphic {
	border-radius: 3px;
	height: 150px;
}

.formSection {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	border-bottom: 1px solid var(--gray);
	padding-bottom: 20px;
	width: 100%;
}

.formRowTop {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: flex-start;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	margin: 20px 0px;
	padding: 0px 20px;
	max-width: 640px;
	width: 100%;
	min-height: 52px;
}
.formRowTop.thin {
	display: flex;
	flex-direction: row;
	align-items: space-between;
	justify-content: flex-start;
	box-sizing: border-box;
	margin: 0px;
	padding: 0px 12px;
	max-width: 640px;
	min-height: 0px;
	width: 100%;
}
.formRowTop.thin .fieldTextContainer {
	justify-content: flex-start;
	font-size: 0.875em;
	font-weight: 600;
	margin: 0px;
}
.formRowTop.thin .formChoices {
	align-items: flex-end;
	padding-right: 12px;
}

.formColumnCenter {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	margin: 20px 0px;
	padding: 0px 20px;
	width: 100%;
	min-height: 52px;
}

.formRowCenter {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	margin: 20px 0px;
	padding: 0px 20px;
	max-width: 640px;
	width: 100%;
	min-height: 52px;
}
.formRowCenter.thin {
	margin: 6px 0px;
}
.thinCont {
	box-sizing: border-box;
	padding-right: 12px;
	margin: 0px;
	width: 100%;
}

.formAddressRowCenter {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	margin: 0px;
	padding: 0px 20px;
	max-width: 640px;
	width: 100%;
	min-height: 52px;
}

.formRowThin {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	margin: 10px 0px;
	padding: 0px 20px;
	max-width: 640px;
	width: 100%;
	min-height: 52px;
}

.formSubmit {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	gap: 12px;
}

.addressDisplay {
	display: block;
	border: 1px solid var(--gray);
	border-radius: var(--borderRadiusReg);
	padding: 10px;
	margin: 20px 0px;
	background-color: var(--white);
}

.formSubmit .submitBtn,
.formSubmit .submitBtnSecondary {
	margin: 0px 20px;
}

.formChoices {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: flex-start;
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	width: 280px;
	max-height: 400px;
	margin: 0px 0px 0px 30px;
}

.formChoices.horizontal {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: flex-start;
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	width: 280px;
	max-height: 400px;
	margin: 0px 0px 0px 30px;
}

.radioLabel {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: flex-start;
	width: 160px;
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	margin: 5px 0px;
}
.radioLabel span {
	padding-top: 3px;
}
.radioLabel span,
.checkboxLabel span {
	min-width: 200px;
}

.horizontalRadios {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	gap: 12px;
}
.horizontalRadios span {
	min-width: 40px;
	width: 60px;
}
.horizontalRadios .radioLabel {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	min-width: 100px;
	width: 100px;
}

.checkboxLabel {
	width: 26px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.checkboxLabelAbsolute {
	width: 100%;
	position: absolute;
	left: 90%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.orderProductsQuantity {
	text-align: center;
	border: 1px solid var(--turqDark);
	width: 25px;
	height: 25px;
	margin: 0px;
	padding: 0px;
}

.lightBorderSection {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	width: 100%;
	border-bottom: 0.8px solid var(--gray);
}

.openBtn {
	height: 40px;
	width: 100px;
	padding-left: 5px;
	padding-right: 5px;
	margin: 5px;
	font-size: 0.875em;
	border-radius: 25px;
	cursor: pointer;
	border: none;
	color: var(--white);
	background-color: var(--turqMain);
	outline: none;
}

.openBtn:hover {
	background-color: var(--turqDark);
	-webkit-box-shadow: 1px 0px 6px 1px var(--black);
	box-shadow: 1px 0px 6px 1px var(--black);
}

.orderLink {
	cursor: pointer;
}
.orderLink.unread {
	color: var(--turqDark);
}

.orderLink:hover {
	color: var(--turqDark);
}

.chosenAddress {
	width: 300px;
	max-width: 95%;
	padding: 20px;
	border-radius: 25px;
	-webkit-box-shadow: 0px 0px 6px var(--black);
	box-shadow: 0px 0px 6px var(--black);
	-ms-flex-item-align: center;
	-ms-grid-row-align: center;
	align-self: center;
}

/* NEW Order Form */
.orderForm {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-item-align: center;
	-ms-grid-row-align: center;
	align-self: center;
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	max-width: 100%;
	padding-bottom: 100px;
}
.orderForm .btnCont button {
	margin: 10px 0px;
}
.orderForm .btnContCol {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: flex-start;
	max-width: 300px;
	gap: 12px;
}
.orderForm .btnContCol button {
	width: 200px;
}
.orderForm .innerCont {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: flex-start;
	-ms-flex-item-align: center;
	-ms-grid-row-align: center;
	align-self: center;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	height: 100%;
	gap: 184px;
}
.orderForm .formStep {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	min-height: 100%;
	height: 500px;
}
.orderForm .formStep .btnCont {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	gap: 20px;
	margin: 20px 0px;
}
.orderFormItems {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	position: relative;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	overflow: hidden;
	max-width: 100vw;
	padding: 24px 0px;
}
.orderLoading {
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: flex-start;
	align-self: center;
	margin-top: 60px;
	max-width: 100%;
	height: 300px;
	width: 400px;
	gap: 60px;
}

.patientCont {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	margin: 24px 0px 6px 0px;
	gap: 24px;
}
.patientCont .patientData {
	margin: 10px 0px;
	text-align: left;
	font-weight: 600;
	font-size: 1em;
}
.patientCont .label {
	color: var(--gray-500);
	font-size: 0.75em;
}

/** CSS for NPM Phone input */
.PhoneInput {
	max-width: 100%;
	width: 480px;
}
.PhoneInputInput {
	border: 1px solid var(--disabled);
	padding: 0px 40px 0px 12px;
	color: var(--black);
	border-radius: 3px;
	margin: 5px 0px;
	font-size: 1em;
	height: 38px;
}

/** NEW Team Registration Form */
.teamRegisterForm {
	background-color: var(--gray-300);
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	border-radius: 24px;
	max-width: 1540px;
	padding: 70px;
	width: 100%;
}
.teamRegisterForm .overflowCont {
	background-color: var(--gray-50);
	border-radius: 20px;
	padding: 40px 92px;
	margin-top: 24px;
	overflow: hidden;
}
.teamRegisterForm .innerCont {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: flex-start;
	-ms-flex-item-align: center;
	-ms-grid-row-align: center;
	align-self: center;
	background-color: var(--gray-50);
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	margin-bottom: 140px;
}
.teamRegisterForm .formStep {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	min-width: 1216px;
}

.teamRegisterForm .thinCol {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	min-width: 40px;
}
.teamRegisterForm .thinCol .iconCont {
	margin: auto 0px 16px 0px;
}
.teamRegisterForm .thinCol .iconCont svg {
	fill: var(--gray-500);
}

.teamRegisterForm .avatarRow {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	gap: 8px;
}
.teamRegisterForm .avatarRow .teamChoicesIcon {
	cursor: pointer;
}
.teamRegisterForm .previewIcon {
	height: 40px;
	width: 40px;
	border-radius: 50%;
	cursor: pointer;
}
.teamRegisterForm .addBtn {
	margin-left: 310px;
}
.teamRegisterForm .companyLogo {
	height: 140px;
}
.teamRegisterForm .innerCont .lds-dual-ring {
	margin: 0px;
}
.teamRegisterForm .linkText:hover a {
	color: var(--primary-700-main);
}
.teamRegisterForm h3 {
	color: var(--gray-900);
}
.teamRegisterForm h4 {
	color: var(--gray-900);
}
.teamRegisterForm .pageHeader {
	margin-left: 24px;
}
.teamRegisterForm .fieldDesc {
	color: var(--gray-600);
	font-size: 0.875em;
	margin-bottom: 0px;
	font-weight: 300;
}
.teamRegisterForm .inviteRow {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-flex: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	gap: 16px;
}
.teamRegisterForm .formNav {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	padding-top: 16px;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}
.teamRegisterForm .formNav .filler {
	width: 164px;
}
.teamRegisterForm .formSection {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	border-bottom: 1px solid var(--gray-300);
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: flex-start;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	margin-bottom: 24px;
}
.teamRegisterForm .formSection:last-child {
	margin-bottom: 0px;
}
.teamRegisterForm .formSection .sectionRow {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	width: 100%;
	gap: 32px;
}
.teamRegisterForm .formSection .sectionRow .btnCont {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;

	margin: 0px;
}
.teamRegisterForm .formSection .wideCol {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	max-width: 100%;
	width: 100%;
}
.teamRegisterForm .formSection .checkRow {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	margin: 8px 0px;
	gap: 32px;
}
.teamRegisterForm .formSection .checkRow p {
	color: var(--gray-700);
	font-size: 0.875em;
	font-weight: 600;
	width: 280px;
}
.teamRegisterForm .formSection .innerCol {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	width: 100%;
}
.teamRegisterForm .formSection .innerCol:nth-child(1) {
	max-width: 280px;
}
.teamRegisterForm .formSection .innerCol:nth-child(2) {
	max-width: 512px;
}
.teamRegisterForm .btnCont.reverse {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: end;
	-ms-flex-align: end;
	align-items: flex-end;
	height: 100%;
	margin-bottom: 5px;
}

.teamRegisterForm .formColumnCenter,
.teamRegisterForm .formRowCenter {
	margin: 3px 0px;
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: flex-start;
	padding: 0px;
}
.teamRegisterForm .formRowCenter .inputLabel {
	padding: 0px;
	margin: 0px;
}
.teamRegisterForm .formSection .innerCol .selectCustom {
	margin: 8px 0px;
}
.teamRegisterForm .formSection .innerCol .inputContainer {
	margin: 3px 0px;
}
.teamRegisterForm .formSection .innerCol .inputContainer:nth-child(1),
.teamRegisterForm .formSection .innerCol .inputContainer:nth-child(1) .textFieldInput {
	margin: 0px 0px 3px 0px;
}
.teamRegisterForm .teamPicture {
	max-width: 100%;
	width: 400px;
}

/** TOGGLE */
.toggle {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	box-sizing: border-box;
	width: 100%;
}
.toggle .toggleLabel {
	font-size: 0.875em;
	font-weight: 600;
}
.toggle .toggleLabel p {
	margin: 0px;
}
.toggle .toggleButton {
	cursor: pointer;
	display: -webkit-box;
	display: -ms-flexbox;
	align-items: center;
	display: flex;
	transition: background-color 0.3s ease-in-out;
	background-color: var(--smoke-gray-300);
	border-radius: 12px;
	padding: 2px;
	width: 36px;
}
.toggle .toggleButton .circle {
	transition: transform 0.3s ease-in-out;
	fill: var(--white);
	height: 17px;
	width: 17px;
}
.toggle .toggleButton.checked {
	background-color: var(--primary-500);
}
.toggle .toggleButton.checked .circle {
	transform: translateX(19px);
}

@media only screen and (max-width: 1216px) {
	.teamRegisterForm .formSection .sectionRow {
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}
}
@media only screen and (max-width: 1024px) {
	.formSubmit .submitBtn {
		margin: 20px 0px;
	}

	.flexModal {
		padding: 20px 30px;
		min-width: 250px;
		max-width: 90vw;
		width: 90vw;
	}

	.multiStepForm {
		padding: 12px;
	}
}

@media only screen and (max-width: 768px) {
	.multiStepForm .sliderMask {
		padding: 24px 12px;
	}
	.multiStepForm .sliderMask::before {
		width: 12px;
	}
	.multiStepForm .sliderMask::after {
		width: 12px;
	}
	.multiStepForm .btnCont {
		-webkit-box-orient: vertical;
		-webkit-box-direction: reverse;
		-ms-flex-direction: column-reverse;
		flex-direction: column-reverse;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		gap: 12px;
	}
	.multiStepForm .btnCont .formSubmit {
		-webkit-box-orient: vertical;
		-webkit-box-direction: reverse;
		-ms-flex-direction: column-reverse;
		flex-direction: column-reverse;
	}
	.multiStepForm .btnCont .primaryBtns {
		-webkit-box-orient: vertical;
		-webkit-box-direction: reverse;
		-ms-flex-direction: column-reverse;
		flex-direction: column-reverse;
		margin-left: 0px;
	}
	.multiStepForm .sectionRow {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		padding: 0px 3px;
	}
	.teamRegisterForm {
		border-radius: 0px;
		padding: 24px 0px;
	}
	.teamRegisterForm .overflowCont {
		background-color: var(--gray-50);
		border-radius: 20px;
		padding: 12px 24px;
		border-radius: 0px;
		margin-top: 24px;
		overflow: hidden;
	}
}
@media only screen and (max-width: 465px) {
	.multiStepForm {
		padding: 0px;
	}
	.orderFilter {
		padding: 0px 12px;
	}
	.orderFilter .btnCont {
		-webkit-box-orient: vertical;
		-webkit-box-direction: reverse;
		-ms-flex-direction: column-reverse;
		flex-direction: column-reverse;
	}
	.formRowTop {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		text-align: start;
	}

	.orderForm .formStep .btnCont {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
	}
	.fieldTextContainer.radio {
		-webkit-box-pack: start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		text-align: start;
	}
	.formChoices {
		margin: 6px 0px 0px 0px;
	}

	.orderForm:after {
		width: 3%;
	}
	.orderForm:before {
		width: 3%;
	}

	.fadeInModal {
		--left: 12px;
		--bottom: 6px;
		left: var(--left);
		padding: 6px;
		width: 300px;
	}

	.fadeOutModal {
		--left: -340px;
		--bottom: 6px;
		left: var(--left);
		padding: 6px;
		width: 300px;
	}
}
